<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.mykeys" class="cp-user-nav-item me-1" size="sm"></fa-icon>
				License Keys
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-primary me-2" routerLink="register"
				ngbTooltip="Add Zixi Keys you've been given in the past" placement="bottom-end">
				<fa-icon [icon]="['fas', 'layer-plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Register Keys</span>
			</button>

			<button *ngIf="orgUsers && orgUsers.length !== 0 && userKeys && userKeys.length !== 0" type="button"
				class="btn btn-outline-primary me-2" routerLink="share" ngbTooltip="Share keys with members of your organization"
				placement="bottom-end">
				<fa-icon [icon]="['fas', 'share-alt']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Share</span>
			</button>

			<!-- <button *ngIf="userKeys.length !== 0" type="button" class="btn btn-outline-secondary me-2"
				(click)="emailUserKeysReport()"
				ngbTooltip="E-Mail me a keys report for this user (including usage data).">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				<span class="cp-action-button-text">E-Mail Usage Report</span>
			</button> -->

			<button *ngIf="userKeys.length !== 0" type="button" class="btn btn-outline-secondary me-2"
				(click)="openUserKeysReport('meter-data')" ngbTooltip="Generate a report with meter usage data across all my keys"
				placement="bottom-end">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				<span class="cp-action-button-text">Usage Report (Meter)</span>
			</button>

			<button *ngIf="userKeys.length !== 0 && showBxProtocolStats" type="button" class="btn btn-outline-secondary me-2"
				(click)="openUserKeysReport('protocol-data')" ngbTooltip="Generate a report with protocol usage data across all my keys"
				placement="bottom-end">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				<span class="cp-action-button-text">Usage Report (Protocol)</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="loadTables(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="bottom-end">
				</fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-active-keys-tab" aria-controls="nav-active-keys" data-bs-target="#nav-active-keys">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" size="sm"></fa-icon>
				Active
				<span *ngIf="!loading || activeKeysToShow.length > 0" class="cp-tab-badge">
					{{ activeKeysToShow.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="inactiveKeysToShow.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-inactive-keys-tab" aria-controls="nav-inactive-keys" data-bs-target="#nav-inactive-keys">
				<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
				Disabled/Expired
				<span *ngIf="!loading || inactiveKeysToShow.length > 0" class="cp-tab-badge">
					{{ inactiveKeysToShow.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="deletedKeysToShow.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-deleted-keys-tab" aria-controls="nav-deleted-keys" data-bs-target="#nav-deleted-keys">
				<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
				Trash
				<span *ngIf="!loading || deletedKeysToShow.length > 0" class="cp-tab-badge">
					{{ deletedKeysToShow.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="userSnoozes.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-snoozes-tab"
				aria-controls="nav-snoozes" data-bs-target="#nav-snoozes">
				<fa-icon [icon]="['fas', 'snooze']" size="sm"></fa-icon>
				Snoozes/Pauses
				<span *ngIf="!loading || userSnoozes.length > 0" class="cp-tab-badge">
					{{ userSnoozes.length | number }}
				</span>
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-active-keys" role="tabpanel" aria-labelledby="nav-active-keys-tab">
			<div class="container">

				<div *ngIf="availableSelfServicePackageTemplates && availableSelfServicePackageTemplates.length !== 0"
					class="border p-2 mb-2 cp-general-width-limit">
					<div class="fw-bold border-bottom mb-2 pb-2">
						License Keys You Can Generate
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="These are keys you can generate yourself that are tied to Packages that you are subscribed to.">
						</fa-icon>
					</div>

					<div *ngFor="let packageTemplate of availableSelfServicePackageTemplates">
						<button type="button" class="btn btn-outline-info btn-sm mb-2"
							(click)="generateKeyViaTemplate(packageTemplate.package_id, packageTemplate.template_id)" [disabled]="loading">
							<fa-icon icon="key" size="sm" [spin]="loading"></fa-icon>
							Generate Key
						</button>
						{{ getPackage(packageTemplate.package_id).name }}
						<fa-icon [icon]="['fas', 'question-circle']" [ngbPopover]="popupContent" popoverClass="cp-popover-std"
							triggers="mouseenter:mouseleave" container="body"></fa-icon>:
						{{ packageTemplate.name }}
						<ng-template #popupContent>
							<div class="cp-popover-banner">{{ getPackage(packageTemplate.package_id).name }}</div>
							<div class="cp-pre-text">{{ getPackage(packageTemplate.package_id).user_description }}</div>
						</ng-template>
					</div>
				</div>

				<div *ngIf="activeKeysToShow.length !== 0" class="row g-1">
					<div class="col-6 mb-1">
						<button *ngIf="!loading && activeKeysToShow.length !== 0 && !editMode" class="btn btn-outline-primary btn-sm"
							type="button" (click)="editMode = !editMode" ngbTooltip="Set/Update the labels for your keys"
							placement="bottom-start">
							<fa-icon [icon]="['fas', 'pencil']" size="sm"></fa-icon> Update Labels
						</button>

						<!-- <button *ngIf="editMode" class="btn btn-primary btn-sm" type="button" (click)="saveLabels()">
							Save Changes
						</button> -->
					</div>

					<div class="col-6 text-end mb-1">
						<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()">
							<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Collapse Warnings
						</button>
						<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()">
							<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Expand Warnings
						</button>
					</div>
				</div>

				<div *ngIf="!loading && activeKeysToShow.length === 0" class="cp-light-message">
					You don't have any active (enabled+unexpired) keys.
				</div>

				<div [hidden]="editMode">
					<app-keys-table #keysTable1></app-keys-table>
				</div>

				<form *ngIf="editMode && theForm" [formGroup]="theForm">
					<table class="cp-std-table ">
						<thead>
							<tr>
								<th>Label</th>
								<th>Product</th>
								<th>Type</th>
								<th>Key</th>
							</tr>
						</thead>

						<tbody *ngIf="activeKeysToShow.length !== 0">
							<tr *ngFor="let item of activeKeysToShow">
								<td>
									<input *ngIf="editMode" type="text" [id]="'label_' + item['__user_id']"
										[formControlName]="'label_' + item['__user_id']" class="form-control form-control-sm" />
								</td>
								<td>{{ niceKeyProduct(item.product) }}</td>
								<td>{{ item.type | titlecase }}</td>
								<td class="cp-key text-nowrap">
									<span [ngbPopover]="popupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ item.key }}
									</span>
								</td>
								<ng-template #popupContent>
									<div class="cp-popover-banner">{{ item.activation_key }}</div>
									<div class="cp-pre-text">{{ popOverTools.getKeyPopoverLines(item, keyProducts, [], [],
										true).join('\n')}}</div>
								</ng-template>
							</tr>
						</tbody>
					</table>
					<button *ngIf="editMode" class="btn btn-primary btn-sm" type="button" (click)="saveLabels()">
						Save Changes
					</button>
				</form>
			</div>
		</div>

		<div class="tab-pane" id="nav-inactive-keys" role="tabpanel" aria-labelledby="nav-inactive-keys-tab">
			<div class="container">

				<div *ngIf="inactiveKeysToShow.length !== 0" class="text-end mb-2">
					<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()">
						<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Collapse Warnings
					</button>
					<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()">
						<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Expand Warnings
					</button>
				</div>

				<div *ngIf="!loading && inactiveKeysToShow.length === 0" class="cp-light-message">
					You don't have any inactive (disabled or expired) keys.
				</div>

				<app-keys-table #keysTable2></app-keys-table>
			</div>
		</div>

		<div class="tab-pane" id="nav-deleted-keys" role="tabpanel" aria-labelledby="nav-deleted-keys-tab">
			<div class="container">

				<div class="row g-1">
					<div class="col-6 mb-1">
						<button *ngIf="!loading && deletedKeysToShow.length !== 0" class="btn btn-outline-danger btn-sm" type="button"
							(click)="emptyTrash()" ngbTooltip="Permanently remove these keys from your account" placement="bottom-start">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon> Empty Trash
						</button>
					</div>

					<div class="col-6 text-end mb-1">
						<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()">
							<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Collapse Warnings
						</button>
						<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()">
							<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Expand Warnings
						</button>
					</div>
				</div>

				<div *ngIf="!loading && deletedKeysToShow.length === 0" class="cp-light-message">
					You don't have any deleted keys.
				</div>

				<app-keys-table #keysTable3></app-keys-table>
			</div>
		</div>

		<div class="tab-pane" id="nav-snoozes" role="tabpanel" aria-labelledby="nav-snoozes-tab">
			<div class="container">

				<table *ngIf="userSnoozes && userSnoozes.length !== 0" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th class="border-start">Label</th>
							<th class="border-start">Key</th>
							<th class="border-start">Snooze/Pause Type</th>
							<th class="border-start">Resume Notifications On</th>
						</tr>
					</thead>

					<tbody>
						<tr *ngFor="let snooze of userSnoozes" [routerLink]="['/' + appConstants.urls.mykeys, snooze.user_key_id]"
							class="cp-pointer">
							<td class="border-start text-nowrap">
								<a [routerLink]="['/' + appConstants.urls.mykeys, snooze.user_key_id]">
									{{ getLabelFromId(snooze.user_key_id) }}
								</a>
							</td>
							<td class="border-start text-nowrap">
								<a [routerLink]="['/' + appConstants.urls.mykeys, snooze.user_key_id]">
									{{ getKey(snooze.user_key_id) }}
								</a>
							</td>
							<td class="border-start text-nowrap">
								{{ miscTools.fetchLabel(appConstants.keySnoozeTypes, snooze.snooze_type) }}
							</td>
							<td class="border-start">
								{{ snooze.expires_at | date: appConstants.shortPageDateFmt:'UTC' }}
								({{ textTools.niceDaysText(snooze.expires_at) }})
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>