import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { MyDocumentsService } from '../app-sections/my-documents/my-documents.service';
import { MyBuildsService } from '../app-sections/my-builds/my-builds.service';
import { MyFileSharingService } from '../app-sections/my-file-sharing/my-file-sharing.service';
import { MyPackagesService } from '../app-sections/my-packages/my-packages.service';
import { MyPartnersService } from '../app-sections/my-partners/my-partners.service';
import { MyKeysService } from '../app-sections/my-keys/my-keys.service';
import { MySettingsService } from '../app-sections/my-settings/my-settings.service';
import { DashboardService } from '../app-sections/dashboard/dashboard.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';


@Injectable({
	providedIn: 'root'
})
export class LoginService {
	// lastUrl: string;
	// kickedOutOf: string;
	private debug = false;

	constructor(
		private route: ActivatedRoute,
		private authService: AuthService,
		private myDocumentsService: MyDocumentsService,
		private myBuildsService: MyBuildsService,
		private myFileSharingService: MyFileSharingService,
		private myPackagesService: MyPackagesService,
		private myPartnersService: MyPartnersService,
		private myKeysService: MyKeysService,
		private mySettingsService: MySettingsService,
		private dashboardService: DashboardService,
		private uiAlertsService: UiAlertsService,
		private http: HttpClient) { }

	setLastURL(lastUrl: string, kickedOutOf: string = '') {
		sessionStorage.setItem('authentication.lastUrl', lastUrl);
		sessionStorage.setItem('authentication.kickedOutOf', kickedOutOf);
	}

	getLastURL() {
		return (sessionStorage.getItem('authentication.lastUrl'));
	}

	getKickedOutOf() {
		return (sessionStorage.getItem('authentication.kickedOutOf'));
	}

	login(email: string, password: string) {
		const offset = new Date().getTimezoneOffset() * -1;
		let tzid = '';
		if (Intl)
			tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

		const postData = { email, password, offset, tzid };
		return this.http
			.post<Models.AuthUser>(AppConstants.apiUrl + AppConstants.apiUrls.auth + AppConstants.apiUrls.login, postData)
			.pipe(
				catchError(this.handleError),
				tap((retUser) => {
					if (this.debug) { console.log('back from from server'); }
					if (this.debug) { console.log(retUser); }

					// sessionStorage.setItem(AppConstants.localStorageUserKey, JSON.stringify(retUser));
					this.adjustLastUrl(retUser);
					this.authService.setAuthUser(retUser);
					this.dashboardService.checkVersion(true);
				}));
	}

	autoLogin() {
		// TBD only attempt this if a session cookie exists...
		// ngx-cookie
		return this.http
			.get<Models.AuthUser>(AppConstants.apiUrl + AppConstants.apiUrls.auth + AppConstants.apiUrls.autologin)
			.pipe(
				tap((retUser) => {
					if (this.debug) { console.log('back from from server in autologin'); }
					if (this.debug) { console.log(retUser); }
					this.adjustLastUrl(retUser);
					this.authService.setAuthUser(retUser);
				}));
	}

	logout(wipeLastUrl = true) {
		// sessionStorage.removeItem(AppConstants.localStorageUserKey);
		if (wipeLastUrl) {
			this.setLastURL('', '');
		}
		this.authService.setAuthUser(null);
		this.myDocumentsService.wipe();
		this.myBuildsService.wipe();
		this.myFileSharingService.wipe();
		this.myPackagesService.wipe();
		this.myPartnersService.wipe();

		this.myKeysService.wipe();
		this.mySettingsService.wipe();
		this.dashboardService.wipe();
		this.uiAlertsService.clearAll('LOGIN');
		return this.http
			.get(AppConstants.apiUrl + AppConstants.apiUrls.auth + AppConstants.apiUrls.logout)
			.pipe(
				catchError(this.handleError),
				tap(() => {
					if (this.debug) { console.log('back from from server in logout'); }
				}));
	}

	getSSOUrl() {
		return this.http
			.get<{ redir: string }>(AppConstants.apiUrl + AppConstants.apiUrls.auth + AppConstants.apiUrls.sso + '/auth');
	}

	checkSSOReturn(state: string, code: string) {
		const offset = new Date().getTimezoneOffset() * -1;
		let tzid = '';
		if (Intl)
			tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;

		const postData = { state, code, offset, tzid };

		return this.http
			.post<Models.AuthUser>(AppConstants.apiUrl
				+ AppConstants.apiUrls.auth + AppConstants.apiUrls.sso + '/return', postData)
			.pipe(
				catchError(this.handleError),
				tap((retUser) => {
					if (this.debug) { console.log('back from from server return'); }
					if (this.debug) { console.log(retUser); }
					if (retUser && retUser.id && +retUser.id !== 0 && retUser.role && retUser.role !== '') {
						if (this.debug) { console.log('calling authService.setAuthUser'); }
						if (this.debug) { console.log('retUser.role=' + retUser.role); }
						this.adjustLastUrl(retUser);
						this.authService.setAuthUser(retUser);
					}
				}));
	}

	// do any redirect on login based on some condition...
	adjustLastUrl(theUser: Models.AuthUser) {
		// if (theUser && !AppConstants.notificationsStaffOnly || ValidationTools.checkRole(theUser.role, AppConstants.staffUserRole)) {
		if (theUser) {
			const newFeatureAlerted = TextTools.getUserPropValue(theUser, AppConstants.newFeatureAlertedKey);
			if (!newFeatureAlerted || newFeatureAlerted !== 'yes') {
				this.uiAlertsService.addMsg(
					'This portal supports e-mail notifications.  You can customize your preferences or opt out from here.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs * 4);
				this.setLastURL('/' + AppConstants.urls.mynotifications, '');
			}
		} // if
	}

	catchLastURL(kickedOutOf: string) {
		let lastUrl = this.getLastURL();
		if (!lastUrl || lastUrl === '') {
			lastUrl = this.route.snapshot['' + '_routerState'].url;
			this.setLastURL(lastUrl, kickedOutOf);
		}
	}

	impersonate(id: number) {
		console.log('in impersonate');

		return this.http
			.post<Models.AuthUser>(AppConstants.apiUrl + AppConstants.apiUrls.auth + '/impersonate/' + id, {})
			.pipe(
				tap((retUser) => {
					if (this.debug) { console.log('back from from server in impersonate'); }
					if (this.debug) { console.log(retUser); }
					this.adjustLastUrl(retUser);
					this.authService.setAuthUser(retUser);
				}));
	}


	private handleError(errorResponse: HttpErrorResponse) {
		let errorMessage = '';

		errorMessage = 'An unknown error has occured.';
		if (errorResponse && errorResponse.error && errorResponse.error.error) {
			errorMessage = errorResponse.error.error;
		}

		return throwError(errorMessage);
	}

}
