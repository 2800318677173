import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import BuildInfo from 'appshared/build-info';
import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	private systemMessages: Models.SystemMessage[];
	private ackedSystemMessages: Models.SystemMessage[];

	private generalNotifications: string[];
	private generalWarnings: string[];

	constructor(
		private http: HttpClient,
		private uiAlertsService: UiAlertsService) {
	}

	wipe() {
		this.systemMessages = null;
		this.ackedSystemMessages = null;
		this.generalNotifications = null;
		this.generalWarnings = null;
	}

	// **********************************************************************
	async checkVersion(ignoreSession: boolean = false) {
		// compare back-end version with front end version...
		const frontVersion: string = BuildInfo.buildDateTime;

		const url = AppConstants.apiUrl + AppConstants.apiUrls.dashboard + '/build-info';

		const result = await this.http.get<any>(url).toPromise();
		let backVersion: string = '';
		if (result && result['buildDateTime']) backVersion = result['buildDateTime'];

		let updateMessageShown: string = '';
		if (sessionStorage.getItem('dashboardService.updateMessageShown')) updateMessageShown = sessionStorage.getItem('dashboardService.updateMessageShown');

		// console.log('checkVersion f=' + frontVersion + ' b=' + backVersion + ' u=' + updateMessageShown);

		if (frontVersion !== backVersion) {
			if (ignoreSession || updateMessageShown !== 'yes') {
				this.uiAlertsService.addMsg('This application has been updated. Reload this site in your browser to run the latest version.', 'warning', '', false, 0);
				sessionStorage.setItem('dashboardService.updateMessageShown', 'yes')
			} // 
		} else {
			sessionStorage.setItem('dashboardService.updateMessageShown', '')
		} // if
	} // checkVersion

	// modes are activation or hostid
	async getTopUsageCache(timePeriod: string = '', keyTypes: string[] = [], objectType: string = '') {
		try {
			let args: string[] = [];
			if (timePeriod && timePeriod !== '')
				args.push('timePeriod=' + encodeURIComponent(timePeriod));

			if (keyTypes && keyTypes.length !== 0)
				args.push('keyTypes=' + encodeURIComponent(keyTypes.join(',')));

			if (objectType && objectType !== '')
				args.push('objectType=' + encodeURIComponent(objectType));


			const url = AppConstants.apiUrl + AppConstants.apiUrls.dashboard
				+ '/top-usage-cache?' + args.join('&');

			const result = await this.http.get<Models.TopUsageCacheEntry[]>(url).toPromise();
			const arr: Models.TopUsageCacheEntry[] = result;

			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getSystemMessages(getAcked = false, forceRefresh = false) {
		if (getAcked) {
			if (!this.ackedSystemMessages || forceRefresh)
				this.ackedSystemMessages = await this.fetchSystemMessage(getAcked);
			return this.ackedSystemMessages.slice();

		} else {
			if (!this.systemMessages || forceRefresh)
				this.systemMessages = await this.fetchSystemMessage(getAcked);
			return this.systemMessages.slice();
		}
	}

	async fetchSystemMessage(getAcked = false) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.dashboard + '/system-messages';
			if (getAcked) url += '?acked=yes';

			const result = await this.http.get<Models.SystemMessage[]>(url).toPromise();
			const arr: Models.SystemMessage[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getGeneralNotifications(forceRefresh = false) {
		if (!this.generalNotifications || forceRefresh)
			this.generalNotifications = await this.fetchGeneralNotifications();
		return this.generalNotifications.slice();
	}

	async fetchGeneralNotifications() {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.dashboard + '/general-notifications';
			const result = await this.http.get<string[]>(url).toPromise();
			const arr: string[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getGeneralWarnings(forceRefresh = false) {
		if (!this.generalWarnings || forceRefresh)
			this.generalWarnings = await this.fetchGeneralWarnings();
		return this.generalWarnings.slice();
	}

	async fetchGeneralWarnings() {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.dashboard + '/general-warnings';
			const result = await this.http.get<string[]>(url).toPromise();
			const arr: string[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async ackMessage(systemMessage: Models.SystemMessage) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.dashboard
				+ '/system-messages/' + systemMessage.id).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async unAckMessage(systemMessage: Models.SystemMessage) {
		try {
			const result = await this.http.put(AppConstants.apiUrl + AppConstants.apiUrls.dashboard
				+ '/system-messages/' + systemMessage.id, {}).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}



}
