import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-paging-search-bar',
	templateUrl: './paging-search-bar.component.html',
	styleUrls: []
})
export class PagingSearchBarComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;

	@Input() parentApi !: any;

	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = null;
	storageKey: string = '';
	useSession: boolean = true;

	pagingOptions: Models.TablePagingOptions = new Models.TablePagingOptions();
	ranCode: string = '';

	itemCount: number = 0;
	filteredCount: number = 0;

	// perPage: number = 0;
	// pageNum: number = 0;
	// textFilter: string = '';

	columnDefs: any[] = [];
	items: any[] = [];
	// itemsToShow: any[] = [];

	// ------------------------------------------------------------------------
	constructor() {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.ranCode = TextTools.randomString(20);
	}

	// ------------------------------------------------------------------------
	setupPaging(items: any[], columnDefs: any[], storageKey: string, defSortBy: string, defSortDir: string, useSession: boolean = true) {
		this.columnDefs = columnDefs;
		this.storageKey = storageKey;
		this.items = items;
		this.itemCount = items.length;
		this.useSession = useSession;

		if (this.useSession) {
			if (this.storageKey !== '' && sessionStorage.getItem(this.storageKey)) {
				try {
					this.displayOptions = JSON.parse(sessionStorage.getItem(this.storageKey));
				} catch (ex) { }
			} // if
		} else {
			if (this.storageKey !== '' && localStorage.getItem(this.storageKey)) {
				try {
					this.displayOptions = JSON.parse(localStorage.getItem(this.storageKey));
				} catch (ex) { }
			} // if
		} // if
		if (!this.displayOptions) {
			this.displayOptions = new Models.TableDisplayOptions();
			this.displayOptions.sortBy = defSortBy;
			this.displayOptions.sortDir = defSortDir;
		} // if
		this.setSortBy(null);
	} //

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		if (field === null) {
			field = this.displayOptions.sortBy;
		} else {
			if (field === this.displayOptions.sortBy) {
				if (this.displayOptions.sortDir === 'asc')
					this.displayOptions.sortDir = 'desc';
				else
					this.displayOptions.sortDir = 'asc';
				this.saveOptions();
				this.items.reverse();
				this.filterItems();
				return;
			} else {
				this.displayOptions.sortBy = field;
				this.saveOptions();
			} // if
		} // if

		this.sortItems(this.items, this.columnDefs, this.displayOptions.sortBy, this.displayOptions.sortDir);
		this.filterItems();
	} // setSortBy

	// ------------------------------------------------------------------------
	filterItems() {
		// call parent to get filtered list based on text filter and full list (which parent has)
		const tmpList: any = this.parentApi.filterItems(this.getMatchWords(this.displayOptions.textFilter));
		this.filteredCount = tmpList.length;

		this.pagingOptions.pages = [];
		this.pagingOptions.pagingInfo = '';

		let firstIdx: number = -1;
		let lastIdx: number = -1;

		if (tmpList.length === 0) {
			this.pagingOptions.numPages = 0;
			this.pagingOptions.pagingInfo = 'Showing 0 entries';
			this.displayOptions.pageNum = 0;
			this.saveOptions();
		} else {
			this.pagingOptions.numPages = Math.floor(tmpList.length / this.displayOptions.perPage) + (tmpList.length % this.displayOptions.perPage > 0 ? 1 : 0);
			if (this.displayOptions.pageNum === 0 || this.displayOptions.pageNum > this.pagingOptions.numPages) {
				this.displayOptions.pageNum = 1;
				this.saveOptions();
			} // 

			this.pagingOptions.pages = this.makePageNums(this.pagingOptions.numPages, this.displayOptions.pageNum);

			firstIdx = (this.displayOptions.pageNum - 1) * this.displayOptions.perPage;
			lastIdx = (this.displayOptions.pageNum * this.displayOptions.perPage);
			if (lastIdx > tmpList.length) lastIdx = tmpList.length;

			this.pagingOptions.pagingInfo = 'Showing ' + TextTools.formatNumber(firstIdx + 1)
				+ ' to ' + TextTools.formatNumber(lastIdx)
				+ ' of ' + TextTools.formatNumber(tmpList.length) + ' entries';
		} // if

		if (tmpList.length !== this.items.length)
			this.pagingOptions.pagingInfo += '<div class="border border-info my-1 text-center cp-75">Filtered from ' + TextTools.formatNumber(this.items.length) + '</div>';

		const itemsToShow: any[] = [];
		if (firstIdx !== -1 || lastIdx !== -1)
			for (let i = firstIdx; i < lastIdx && i < tmpList.length; i++)
				itemsToShow.push(tmpList[i]);

		// return items to show to parent with latest version of displayOptions
		this.parentApi.updateItemsToShow(itemsToShow, this.displayOptions);
	}

	// ------------------------------------------------------------------------
	saveOptions() {
		if (this.storageKey !== '')
			if (this.useSession)
				sessionStorage.setItem(this.storageKey, JSON.stringify(this.displayOptions));
			else
				localStorage.setItem(this.storageKey, JSON.stringify(this.displayOptions));
	}

	// ------------------------------------------------------------------------
	resetFilters() {
		this.displayOptions.textFilter = '';
		this.saveOptions();
		this.filterItems();
	} // resetFilters

	// ------------------------------------------------------------------------
	setTextFilter() {
		let textFilter: string = '';
		if (document.getElementById('textFilter' + this.ranCode))
			textFilter = document.getElementById('textFilter' + this.ranCode)['value'];
		this.displayOptions.textFilter = textFilter;
		this.saveOptions();
		this.filterItems();
	} // setTextFilter

	// ------------------------------------------------------------------------
	setPageNum(pageNum: number) {
		this.displayOptions.pageNum = pageNum;
		this.saveOptions();
		this.filterItems();
	} // setPageNum

	// ------------------------------------------------------------------------
	setPerPage(perPage: number) {
		this.displayOptions.perPage = perPage;
		this.saveOptions();
		this.filterItems();
	} // setPerPage

	// ------------------------------------------------------------------------
	sortItems(items: any[], columnDefs: any[], sortByColumn: string, sortDir: string) {
		const columDef: any = MiscTools.pickItem(columnDefs, 'field', sortByColumn);
		if (!columDef) return;

		for (const item of items) {
			let sortValue: any = '';

			if (columDef.type === 'baseText') {
				sortValue = item?.[sortByColumn];

			} else if (columDef.type === 'baseNumeric') {
				sortValue = +item?.[sortByColumn];

			} else if (columDef.type === 'baseDate') {
				const theDate = new Date(item?.[sortByColumn]);
				if (isNaN(theDate.getTime()))
					sortValue = -1;
				else
					sortValue = theDate.getTime();
			} // if

			if (columDef.sortType === 'numeric') {
				if (isNaN(sortValue)) sortValue = -1;
			} else {
				if (sortValue == null || sortValue == undefined) sortValue = '';
				sortValue = ('' + sortValue).trim().toLowerCase();
			} // if

			item['__sortValue'] = sortValue;
		} // for

		if (columDef.sortType === 'numeric')
			if (sortDir === 'desc')
				items.sort((a, b) => (+a['__sortValue'] < +b['__sortValue']) ? 1 : -1);
			else
				items.sort((a, b) => (+a['__sortValue'] > +b['__sortValue']) ? 1 : -1);
		else
			if (sortDir === 'desc')
				items.sort((a, b) => (a['__sortValue'] < b['__sortValue']) ? 1 : -1);
			else
				items.sort((a, b) => (a['__sortValue'] > b['__sortValue']) ? 1 : -1);
		return;
	}; // 

	// ------------------------------------------------------------------------
	getMatchWords(textFilter: string): string[] {
		let cleanFilter: string = '';
		if (textFilter && textFilter.trim() !== '') cleanFilter = textFilter.toLowerCase().trim();
		let matchWords: string[] = [];
		if (cleanFilter !== '') {
			if (cleanFilter.startsWith('"') && cleanFilter.endsWith('"')) {
				cleanFilter = cleanFilter.substring(1);
				cleanFilter = cleanFilter.substring(0, cleanFilter.length - 1);
				matchWords = [cleanFilter];
			} else {
				matchWords = cleanFilter.split(' ');
			} // if
		} // if
		return matchWords;
	}

	// ------------------------------------------------------------------------
	makePageNums(numPages: number, pageNum: number): number[] {
		const pages: number[] = [];

		let startPage: number = 1;
		let endPage: number = numPages;
		if (numPages > AppConstants.indexPages + 2) {
			const numPerSide = Math.floor(AppConstants.indexPages / 2);
			startPage = pageNum - numPerSide;
			endPage = pageNum + numPerSide;

			// console.log('numPerSide=' + numPerSide);
			// console.log('startPage a =' + startPage);
			// console.log('endPage a =' + endPage);

			if (startPage <= 0) {
				endPage += (startPage * -1) + 1;
				startPage = 1;

			} else if (endPage > numPages) {

				startPage -= endPage - numPages;
				endPage = numPages;

			} // if
			// console.log('startPage b =' + startPage);
			// console.log('endPage b =' + endPage);
		} // if

		for (let i = startPage; i <= endPage; i++)
			if (i >= 1 && i <= numPages)
				pages.push(i);
		if (pages[0] !== 1) {
			if (pages[0] !== 2)
				pages.unshift(-1);
			pages.unshift(1);
		}
		if (pages[pages.length - 1] !== numPages) {
			if (pages[pages.length - 1] !== numPages - 1)
				pages.push(-1);
			pages.push(numPages);
		} // if

		return pages;
	} //






}
