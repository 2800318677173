import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { OrganizationsService } from '../../organizations/organizations.service';
import { ZenCustomersService } from '../zen-customers.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { UsersService } from '../../users/users.service';

@Component({
	selector: 'app-zenmaster-add-form',
	templateUrl: './zenmaster-add-form.component.html',
	styleUrls: ['./zenmaster-add-form.component.scss']
})
export class ZenmasterAddFormComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	private userSubscription: Subscription;

	orgSelections: any[] = [];
	orgUserSelections: any[] = [];

	allUsers: Models.User[] = [];

	regions: string[] = [];
	regionSelections: any[] = [];

	instanceTypeSelections: any[] = [];
	// amiSelections: any[] = [];

	// randomPassword: string = '';
	// showPassword: boolean = false;

	// other stuff

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private authService: AuthService,
		private zenCustomersService: ZenCustomersService) {
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.initForm();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		this.loading = true;
		const allOrgs = this.organizationsService.getAll();
		this.allUsers = this.usersService.getAll();

		this.regions = await this.zenCustomersService.getTunnelRegions();
		if (!this.regions || this.regions.length === 0) this.regions = ['us-east-1'];

		this.regionSelections = [];
		for (const r of this.regions)
			this.regionSelections.push({
				value: r
			});

		this.orgSelections = [];
		for (const org of allOrgs)
			if (!ValidationTools.hasFlag(org, 'no_zen_sites'))
				this.orgSelections.push({
					id: org.id,
					name: org.name + ' [' + org.otype + '] - ' + org.salesforce_account_owner
				});


		this.instanceTypeSelections = [];
		for (const instanceType of AppConstants.zenInstanceTypes)
			this.instanceTypeSelections.push({ value: instanceType, name: instanceType });

		// this.amiSelections = [];

		// const amis: any[] = await this.zenCustomersService.getAmis();
		// amis.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1);

		// for (const ami of amis)
		// 	this.amiSelections.push({ value: ami.ami_id, name: ami.description + ' (' + TextTools.formatDateNiceUTC(ami.created_at) + ')' });

		// const passwdChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
		// this.randomPassword = TextTools.randomString(12, passwdChars);
		// let loops = 0;
		// const maxLoops = 100;
		// while (loops < maxLoops && TextTools.checkPasswordStrength(this.randomPassword, null) != null) {
		// 	this.randomPassword = TextTools.randomString(12, passwdChars);
		// 	loops++
		// } // while

		this.theForm = new UntypedFormGroup({
			zcp_type: new UntypedFormControl(null, [Validators.required]),
			zcp_org_id: new UntypedFormControl(null, [Validators.required]),
			name: new UntypedFormControl(null, [Validators.required]),
			dns_prefix: new UntypedFormControl(null, [Validators.required]),
			org_users: new UntypedFormControl(null, [Validators.required]),
			reason: new UntypedFormControl(null, [Validators.required]),
			content_analysis: new UntypedFormControl(true),
			automation: new UntypedFormControl(true),
			traceroute_history: new UntypedFormControl(false),
			live_events: new UntypedFormControl(false),
			allow_agentz: new UntypedFormControl(true),
			allow_non_sso_login: new UntypedFormControl(true),
			eip: new UntypedFormControl(null),
			region: new UntypedFormControl('us-east-1', [Validators.required]),
		});

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		let dnsPrefix = this.theForm.value.dns_prefix;
		if (dnsPrefix) dnsPrefix = dnsPrefix.trim().toLowerCase();

		let region = this.theForm.value.region;

		if (!region || region === '') this.errors.push('You must choose a region.');

		if (!dnsPrefix || dnsPrefix === '') {
			this.errors.push('You must provide a DNS Prefix.'); // UI should prevent this

		} else {
			if (AppConstants.restrictedZenNames.includes(dnsPrefix))
				this.errors.push('You cannot use that DNS Prefix.');

			if (dnsPrefix.toLowerCase().replace(/[^a-z0-9]/gi, '') !== dnsPrefix)
				this.errors.push('DNS Prefix cannot have non-alpha numeric characters (no spaces, no punctuation).');

			if (!TextTools.startsWithLetter(dnsPrefix))
				this.errors.push('DNS Prefix must start with a letter.');
		} // if

		const admin_portal_id = +this.theForm.value.org_users;
		if (!admin_portal_id || admin_portal_id === 0)
			this.errors.push('You must choose an admin user account.');

		let reason = this.theForm.value.reason;
		if (reason) reason = reason.trim();

		if (!reason || reason === '')
			this.errors.push('You must provide a reason for launching this ZEN Master site.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		}

		const zenmaster = new Models.ZenMasterCustomer(0, '', '', '', '', null, null, 1, 0, '', '', '', '', '', '', '', '', '', 0, '', '', null, 0, 0, '', 0, 0, 0, 0, 0, 0, 0);

		zenmaster.zcp_type = this.theForm.value.zcp_type;
		zenmaster.zcp_org_id = +this.theForm.value.zcp_org_id;
		zenmaster.name = this.theForm.value.name;
		zenmaster.dns_prefix = dnsPrefix;
		zenmaster.content_analysis = this.theForm.value.content_analysis;
		zenmaster.automation = this.theForm.value.automation;
		zenmaster.traceroute_history = this.theForm.value.traceroute_history;
		zenmaster.eip = this.theForm.value.eip;
		zenmaster.live_events = this.theForm.value.live_events;
		zenmaster.allow_agentz = this.theForm.value.allow_agentz;
		zenmaster.allow_non_sso_login = this.theForm.value.allow_non_sso_login;

		// const admin_user_name = this.theForm.value.admin_user_name;
		// const admin_user_email = this.theForm.value.admin_user_email;

		try {
			const addedZenmaster = await this.zenCustomersService.adminAdd(zenmaster, admin_portal_id, reason, region);
			if (addedZenmaster) {
				this.router.navigate([AppConstants.urls.zencustomers, addedZenmaster.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.zencustomers]);
	}

	selectOrg() {
		const zcp_org_id = +this.theForm.value.zcp_org_id;
		this.orgUserSelections = [];
		if (zcp_org_id && !isNaN(zcp_org_id) && zcp_org_id !== 0) {
			const org = this.organizationsService.getOne(zcp_org_id);
			this.theForm.controls['name'].setValue(org.name);

			// strip out all non alpha-numeric
			let dnsPrefix = org.name.toLowerCase().replace(/[^a-z0-9]/gi, '');
			if (dnsPrefix.length > 20) dnsPrefix = dnsPrefix.substring(0, 20);

			this.theForm.controls['dns_prefix'].setValue(dnsPrefix);

			for (const user of this.allUsers)
				if (user.org_id === zcp_org_id)
					this.orgUserSelections.push({ id: user.id, name: user.name + ' (' + user.email + ')' });
		} // if
	}

	prefillAdminUser() {
		const userId = +this.theForm.value.org_users;
		if (userId && !isNaN(userId) && userId !== 0) {
			const user = this.usersService.getOne(userId);
			if (user) {
				this.theForm.controls['admin_user_name'].setValue(user.name);
				this.theForm.controls['admin_user_email'].setValue(user.email);
			} // if
		} // if
	}


}
