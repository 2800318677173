// tslint:disable: no-string-literal

class TrackRecent {
	// ************************************************************************************************
	static addRecent = (id: number, objectType: string) => {
		const maxRecent = 10;

		// get the recent list...
		const key = 'recentIds.' + objectType;
		let recentIds: number[] = [];
		if (sessionStorage.getItem(key) != null) recentIds = JSON.parse(sessionStorage.getItem(key));

		// check to see if the array includes this one
		// if so, remove it from where it is so it can
		// be added to the end
		const idx = recentIds.indexOf(id);
		if (idx !== -1) recentIds.splice(idx, 1);
		// add this id to the end of the list
		recentIds.push(id);

		// until the array's length is larger than the max,
		// remove the first element
		while (recentIds.length > maxRecent)
			recentIds.splice(0, 1);

		sessionStorage.setItem(key, JSON.stringify(recentIds));
	};

	static getRecent = (objectType: string) => {
		// get the recent list...
		const key = 'recentIds.' + objectType;
		let recentIds: number[] = [];
		if (sessionStorage.getItem(key) != null) recentIds = JSON.parse(sessionStorage.getItem(key));
		return recentIds;
	};


}

export default TrackRecent;
