import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';
import PopOverTools from 'appshared/popover-tools';

import TrackRecent from '../../../../helpers/track-recent';

import { OrganizationsService } from '../organizations.service';
import { UsersService } from '../../users/users.service';
import { ProductsService } from '../../products/products.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { BuildsService } from '../../builds/builds.service';
import { DocumentsService } from '../../documents/documents.service';
import { AuthService } from 'client/app/services/auth.service';
import { MyBuildsService } from '../../my-builds/my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { DownloadLogsService } from '../../download-logs/download-logs.service';
import { OrganizationGroupsService } from '../organization-groups.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { ZenCustomersService } from '../../zen-customers/zen-customers.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { AppSettingsService } from '../../app-settings/app-settings.service';
import { PartnerAdminService } from '../../partner-admin/partner-admin.service';
import { ReportsService } from '../../reports/reports.service';
import { LicensingService } from '../../licensing/licensing.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { DownloadsTableComponent } from 'client/app/components/shared/downloads-table/downloads-table.component';
import { JournalsTableComponent } from 'client/app/components/shared/journals-table/journals-table.component';
import { DownloadableBuildsTableComponent } from 'client/app/components/shared/downloadable-builds-table/downloadable-builds-table.component';
import { DownloadableDocumentsTableComponent } from 'client/app/components/shared/downloadable-documents-table/downloadable-documents-table.component';
import { UsersTableComponent } from 'client/app/components/shared/users-table/users-table.component';
import { KeysTableComponent } from 'client/app/components/shared/keys-table/keys-table.component';

@Component({
	selector: 'app-organization',
	templateUrl: './organization.component.html',
	styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;
	sharedLicenseTools = SharedLicenseTools;
	now = new Date();

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('downloadsTable1') downloadsTable1: DownloadsTableComponent = null;
	@ViewChild('journalsTable1') journalsTable1: JournalsTableComponent = null;
	@ViewChild('dBuildsTable1') dBuildsTable1: DownloadableBuildsTableComponent = null;
	@ViewChild('dDocsTable1') dDocsTable1: DownloadableDocumentsTableComponent = null;
	@ViewChild('usersTable1') usersTable1: UsersTableComponent = null;
	@ViewChild('usersTable2') usersTable2: UsersTableComponent = null;
	@ViewChild('keysTable1') keysTable1: KeysTableComponent = null;
	@ViewChild('keysTable2') keysTable2: KeysTableComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	organization: Models.Organization;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	partnerships: Models.Partnership[] = [];
	zenmasters: Models.ZenMasterCustomer[] = [];

	users: Models.User[] = [];
	users2: Models.User[] = [];
	builds: Models.Build[] = [];
	documents: Models.Document[] = [];
	groups: Models.OrganizationGroup[] = [];
	buildDownloads: Models.DownloadableBuild[] = [];
	documentDownloads: Models.DownloadableDocument[] = [];
	downloadLogs: Models.DownloadLog[] = [];
	adminLogs: Models.AdminLog[] = [];

	allPenTests: Models.BroadcasterPenTest[] = [];
	failedPenTests: Models.BroadcasterPenTest[] = [];
	penTestRunning: boolean = false;

	canAddJournals = false;
	journals: Models.JournalEntry[] = [];

	keys: Models.LPActivation[] = [];
	keys2: Models.LPActivation[] = [];

	keyActiveHostCounts = {};
	recentHostIconPopup = '# Host IDs eporting non-zero traffic over the last ' + AppConstants.recentUsedHostsDays + ' days';

	billingCodes: Models.BillingCode[] = []

	canManagePartners = false;
	showAddPartners = false;

	showAddZenSite = false;

	canEdit = false;
	canDelete = false;
	canEnable = false;

	showUserAdd = false;

	showKeysTab = false;
	canManageKeys = false;

	showBillingCodeTab = false;
	showBillingCodeAuth = false;
	canManageBillingCodes = false;

	showDirectTab = false;
	minDirectForTab = 10;

	allProducts: Models.Product[] = [];
	allPlatforms: Models.Platform[] = [];
	allProductPlatforms: Models.ProductPlatform[] = [];
	productTypesToShow = [];

	flagsToShow: string[] = [];

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;
	spinFavorite = false;
	starIcon = ['fas', 'star'];

	onboardingStatus: string[] = [];

	sfOwnerUser: Models.User = null;
	sfSEUser: Models.User = null;

	zenCustomers = {};

	salesforceAccountInfoBlock = '';
	sfFetching = false;
	expandWarnings = false;

	keyProducts: Models.LicenseProduct[] = [];

	salesForceUrl = '';

	emails: string[] = [];

	canSendNotifications = false;

	parentOrganization: Models.Organization = null;
	childOrganizations: Models.Organization[] = [];

	deleteForm: UntypedFormGroup;
	showDeleteMsg = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private buildsService: BuildsService,
		private documentsService: DocumentsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private organizationGroupsService: OrganizationGroupsService,
		private authService: AuthService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService,
		private downloadLogsService: DownloadLogsService,
		private adminLogsService: AdminLogsService,
		private licensingAdminService: LicensingAdminService,
		private zenCustomersService: ZenCustomersService,
		private uiAlertsService: UiAlertsService,
		private partnerAdminService: PartnerAdminService,
		private reportsService: ReportsService,
		private licensingService: LicensingService,
		private appSettingsService: AppSettingsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.organization = this.organizationsService.getOne(this.id);
			if (!this.organization || this.organization == null || this.organization.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {

				TrackRecent.addRecent(this.id, 'organization');

				this.userSubscription = this.authService.user.subscribe(authUser => {
					this.authUser = authUser;

					if (authUser) {
						this.canAddJournals = ValidationTools.checkAccess(this.authUser, 'add-journals');

						this.canManagePartners = ValidationTools.checkAccess(authUser, 'manage-partnerships')
							&& !ValidationTools.hasFlag(this.organization, 'no_partnerships');

						this.canSendNotifications = ValidationTools.checkAccess(authUser, 'send-general-notifications');

						// this.showAddZenSite = !ValidationTools.hasFlag(this.organization, 'no_zen_sites');

						if (ValidationTools.hasFlag(this.organization, 'restricted')) {
							this.canEdit = ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole);
							this.canDelete = ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole);
							this.showUserAdd = ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole)
								&& !ValidationTools.hasFlag(this.organization, 'no_users');
						} else {
							this.canEdit = ValidationTools.checkAccess(authUser, 'manage-organizations-' + this.organization.otype);
							this.canDelete = this.canEdit && ValidationTools.checkAccess(authUser, 'delete-organizations');
							this.showUserAdd = ValidationTools.checkAccess(authUser, 'manage-basic-users-' + this.organization.otype)
								&& !ValidationTools.hasFlag(this.organization, 'no_users');
						} // if

						this.canEnable = this.canEdit
							&& (!AppConstants.strictOrganizationTypes.includes(this.organization.otype)
								|| this.organization.salesforce_account_id && this.organization.salesforce_account_id !== '');

						this.showKeysTab = !ValidationTools.hasFlag(this.organization, 'no_keys') || this.organization.num_keys > 0;

						this.canManageKeys = ValidationTools.checkAccess(authUser, 'manage-keys')
							&& !ValidationTools.hasFlag(this.organization, 'no_keys');

						const starPref = TextTools.getUserPropValue(authUser, AppConstants.starIconKey);
						if (starPref && starPref !== '') this.starIcon = ['fas', starPref];

						this.showBillingCodeAuth = ValidationTools.checkAccess(authUser, 'view-billing-codes');
						this.canManageBillingCodes = this.canEdit && ValidationTools.checkAccess(authUser, 'manage-billing-codes');

						this.showBillingCodeTab = (!ValidationTools.hasFlag(this.organization, 'no_billing_codes') && this.canManageBillingCodes)
							|| this.organization.num_billing_codes > 0
						this.loadData();
					}
				});
				this.organizationsService.refreshOne(this.id);
			}
		});
	}

	// *********************************************************
	ngOnInit(): void {
		const expandWarnings = localStorage.getItem('organizations.keys.expandWarnings');
		this.expandWarnings = (expandWarnings && expandWarnings === 'true');
	}

	// *********************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// *********************************************************
	async loadData(skipUsers = false) {
		this.loading = true;

		this.flagsToShow = [];
		for (const flag of AppConstants.organizationFlags)
			if (this.organization.flags && this.organization.flags![flag.key] && +this.organization.flags[flag.key] === 1)
				this.flagsToShow.push(flag.label);

		for (const flag of AppConstants.adminOnlyOrganizationFlags)
			if (this.organization.flags && this.organization.flags![flag.key] && +this.organization.flags[flag.key] === 1)
				this.flagsToShow.push(flag.label);

		this.allPlatforms = this.platformsService.getAll();
		this.allProducts = this.productsService.getAll();
		this.allProductPlatforms = this.productsService.getAllProductPlatforms();
		this.salesForceUrl = await this.appSettingsService.getSalesForceUrl();
		this.keyProducts = await this.licensingAdminService.getProducts();

		const orgUsers: Models.User[] = this.usersService.getUsersForOrganization(this.id);
		this.users = [];
		this.users2 = [];
		for (const u of orgUsers)
			if (u.is_enabled === 1)
				this.users.push(u);
			else
				this.users2.push(u);

		if (this.users.length > 0 || this.users2.length > 0) await MiscTools.delay(100);
		if (this.usersTable1) this.usersTable1.updateContent(this.users, 'cp-organization1-view-users-list', { showOrganization: false });
		if (this.usersTable2) this.usersTable2.updateContent(this.users2, 'cp-organization2-view-users-list', { showOrganization: false });

		this.emails = [];
		for (const u of this.users)
			this.emails.push(u.email);

		this.parentOrganization = null;
		if (this.organization.parent_org_id !== 0)
			this.parentOrganization = this.organizationsService.getOne(this.organization.parent_org_id);

		this.childOrganizations = [];
		const allOrgs = this.organizationsService.getAll();
		for (const org of allOrgs)
			if (org.parent_org_id === this.id)
				this.childOrganizations.push(org);

		this.onboardingStatus = ValidationTools.getOrganizationIssues(this.organization);

		for (const productType of AppConstants.productTypes) {
			let countForType = 0;
			for (const product of this.subListOfProducts(productType)) {
				for (const platform of this.subListOfPlatforms(product.id)) {
					const checked = this.findProductPlatform(this.organization.product_platforms, product.id, platform.id) !== -1;
					if (this.findProductPlatform(this.organization.product_platforms, product.id, platform.id) !== -1) countForType++;
				}
			}
			if (countForType !== 0) this.productTypesToShow.push(productType);
		}

		if (this.showBillingCodeTab)
			this.billingCodes = await this.organizationsService.fetchBillingCodes(this.id);

		this.addedByUser = null;
		if (this.organization.added_by && this.organization.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.organization.added_by);

		this.editedByUser = null;
		if (this.organization.edited_by && this.organization.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.organization.edited_by);

		if (this.organization.group_ids && this.organization.group_ids.length !== 0) {
			for (const gid of this.organization.group_ids) {
				const g = this.organizationGroupsService.getOne(gid);
				if (g) this.groups.push(g);
			}
			this.groups.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		if (this.organization.build_ids && this.organization.build_ids.length !== 0) {
			for (const bid of this.organization.build_ids) {
				const b = this.buildsService.getOne(bid);
				if (b) this.builds.push(b);
			}
		}

		for (const group of this.groups) {
			if (group.is_enabled === 1 && group.build_ids && group.build_ids.length !== 0) {
				for (const bid of group.build_ids) {
					if (MiscTools.findIndex(this.builds, bid) === -1) {
						const b = this.buildsService.getOne(bid);
						if (b) this.builds.push(b);
					}
				}
			}
		}
		this.builds.sort((a, b) => (a.version > b.version) ? 1 : -1);

		if (this.organization.document_ids && this.organization.document_ids.length !== 0) {
			for (const did of this.organization.document_ids) {
				const d = this.documentsService.getOne(did);
				if (d) this.documents.push(d);
			}
		}

		for (const group of this.groups) {
			if (group.is_enabled === 1 && group.document_ids && group.document_ids.length !== 0) {
				for (const did of group.document_ids) {
					if (MiscTools.findIndex(this.documents, did) === -1) {
						const d = this.documentsService.getOne(did);
						if (d) this.documents.push(d);
					}

				}
			}
		}
		this.documents.sort((a, b) => (a.label > b.label) ? 1 : -1);

		this.showDirectTab = (this.builds.length + this.documents.length >= this.minDirectForTab);

		this.sfOwnerUser = null;
		if (this.organization.salesforce_account_owner_id && this.organization.salesforce_account_owner_id !== 0)
			this.sfOwnerUser = this.usersService.getOne(this.organization.salesforce_account_owner_id);

		this.sfSEUser = null;
		if (this.organization.salesforce_se_id && this.organization.salesforce_se_id !== 0)
			this.sfSEUser = this.usersService.getOne(this.organization.salesforce_se_id);

		this.partnerships = [];
		const allPartners = this.partnerAdminService.getAll();
		for (const p of allPartners)
			if (p.org_id === this.id)
				this.partnerships.push(p);

		this.zenmasters = [];
		const allZenmasters = this.zenCustomersService.getAll();
		for (const z of allZenmasters)
			if (z.zcp_org_id && z.zcp_org_id === this.id && z.is_enabled === 1)
				this.zenmasters.push(z);

		for (const z of allZenmasters)
			if (z.zcp_org_id && z.zcp_org_id === this.id && z.is_enabled === 0)
				this.zenmasters.push(z);

		this.keyActiveHostCounts = await this.organizationsService.getActiveHostCounts(this.id);

		const orgKeys = await this.organizationsService.fetchKeys(this.id);
		this.keys = [];
		this.keys2 = [];
		for (const item of orgKeys) {
			item['__niceProduct'] = this.niceKeyProduct(item.product);
			item['__popover'] = PopOverTools.getKeyPopoverLines(item, this.keyProducts, [], [], false).join('\n');

			if (this.keyActiveHostCounts[item.id])
				item['__active_hostids'] = this.keyActiveHostCounts[item.id];
			else
				item['__active_hostids'] = '';

			const exp = SharedLicenseTools.getKeyExpiration(item, '', true);
			if ((item.enabled === 0 && !SharedLicenseTools.isSpecialKey(item)) || (exp != null && MiscTools.hasExpired(exp)))
				this.keys2.push(item);
			else
				this.keys.push(item);
		} // for

		if (this.keysTable1) {
			if (this.expandWarnings && !this.keysTable1.expandWarnings) this.keysTable1.toggleWarnings();

			this.keysTable1.updateContent(this.keys, 'cp-organization-view-active-keys',
				{
					staffMode: true,
					addPopovers: true,

					showStaffDelete: false,
					showEnabled: true,
					showUserLabel: false,
					showInfo: true,
					showOrganization: false,
					showFullProduct: false,
					showFullType: false,
					showExpandedActivations: true,
					showNumUsers: true,
					showActiveCount: true,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: true,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: true,
					showMeterIrregularities: true
				});
		} // if

		if (this.keysTable2) {
			if (this.expandWarnings && !this.keysTable2.expandWarnings) this.keysTable2.toggleWarnings();

			this.keysTable2.updateContent(this.keys2, 'cp-organization-view-old-keys',
				{
					staffMode: true,
					addPopovers: true,

					showStaffDelete: false,
					showEnabled: true,
					showUserLabel: false,
					showInfo: true,
					showOrganization: false,
					showFullProduct: false,
					showFullType: false,
					showExpandedActivations: true,
					showNumUsers: true,
					showActiveCount: true,
					showMeterIcon: true,
					showProtocolIcon: true,
					showSnoozed: true,
					showLastTouched: false,
					showSalesEngineer: false,
					showCommercialType: true,
					showMeterIrregularities: true
				});
		} // if

		this.allPenTests = await this.organizationsService.getOrganizationPenTests(this.id);
		this.failedPenTests = [];
		for (const p of this.allPenTests)
			if (p.test_code === 0)
				this.failedPenTests.push(p);


		this.buildDownloads = await this.myBuildsService.fetchBuildsForOrgFromDB(this.id);
		if (this.dBuildsTable1) this.dBuildsTable1.updateContent(this.buildDownloads, 'cp-organization-view-downloadable-builds');

		this.documentDownloads = await this.myDocumentsService.fetchDocumentsForOrgFromDB(this.id);
		if (this.dDocsTable1) this.dDocsTable1.updateContent(this.documentDownloads, 'cp-organization-view-downloadable-documents');

		this.downloadLogs = await this.downloadLogsService.getLogsForOrganization(this.id);
		if (this.downloadsTable1)
			this.downloadsTable1.updateContent(this.downloadLogs, 'cp-organization-view-download-logs', { showUserInfo: true, linkUser: true });

		this.adminLogs = await this.adminLogsService.getLogs(['organization'], this.id);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-organization-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

		this.journals = await this.adminLogsService.getJournals('organization', this.id);
		if (this.journalsTable1)
			this.journalsTable1.updateContent(this.journals);

		this.deleteForm = new UntypedFormGroup({
			confirmation: new UntypedFormControl(null, [Validators.required])
		});

		// await MiscTools.delay(100);
		// if (this.usersTable1) this.usersTable1.updateContent(this.users, 'cp-organization1-view-users-list', { showOrganization: false });
		// if (this.usersTable2) this.usersTable2.updateContent(this.users2, 'cp-organization2-view-users-list', { showOrganization: false });

		this.loading = false;
	}

	// *********************************************************
	buildExtra(id: number): string {
		const groupNames = [];
		for (const group of this.groups)
			if (group.build_ids && group.build_ids.includes(id))
				groupNames.push(group.name);

		if (this.organization.build_ids.includes(id) && groupNames.length !== 0)
			groupNames.push('Direct Access');

		if (groupNames.length !== 0)
			return '(via ' + groupNames.join('; ') + ')';
		else
			return '';
	}

	// *********************************************************
	documentExtra(id: number): string {
		const groupNames = [];
		for (const group of this.groups)
			if (group.document_ids && group.document_ids.includes(id))
				groupNames.push(group.name);

		if (this.organization.document_ids.includes(id) && groupNames.length !== 0)
			groupNames.push('Direct Access');

		if (groupNames.length !== 0)
			return '(via ' + groupNames.join('; ') + ')';
		else
			return '';
	}

	// *********************************************************
	async delete() {
		let bail = false;
		if (this.organization.num_partnerships !== 0) {
			this.uiAlertsService.addMsg('Organizations with partnerships cannot be deleted. Delete them first.',
				'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			bail = true;
		}

		if (this.organization.num_zen_domains !== 0) {
			this.uiAlertsService.addMsg('Organizations linked to ZEN Master sites cannot be deleted. Unlink them first.',
				'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			bail = true;
		}

		if (bail) return;

		this.loading = true;

		let confirmation = '';
		if (this.deleteForm.value.confirmation) confirmation = this.deleteForm.value.confirmation;

		if (confirmation.toLowerCase() !== 'delete') {
			this.showDeleteMsg = true;
			this.loading = false;
			return;
		} // if

		if (document.getElementById("closeDeleteModalButton"))
			document.getElementById("closeDeleteModalButton").click();

		const result = await this.organizationsService.deleteOne(this.organization.id);
		if (result) {
			this.uiAlertsService.addMsg('The organization (' + this.organization.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.organizations]);
		} else {
			return false;
		}
	}

	// *********************************************************
	async disable() {
		if (confirm('Are you sure you want to disable this Organization?')) {
			const result = await this.organizationsService.toggleEnabled(this.organization.id);
			if (result) {
				this.organization = result;
				this.onboardingStatus = ValidationTools.getOrganizationIssues(this.organization);
				this.loadData();
			} else {
				return false;
			}
		}
	}

	// *********************************************************
	async enable() {
		if (confirm('Are you sure you want to enable this Organization?')) {
			const result = await this.organizationsService.toggleEnabled(this.organization.id);
			if (result) {
				this.organization = result;
				this.onboardingStatus = ValidationTools.getOrganizationIssues(this.organization);
				this.loadData();
			} else {
				return false;
			}
		}
	}

	// *********************************************************
	async resetBillCodeAuthCode(id: number) {
		if (confirm('Are you sure you want to reset this billing code\'s authorization code?')) {
			const result = await this.organizationsService.resetBillCodeAuthCode(this.organization.id, id);
			if (result) {
				const returnedBC: Models.BillingCode = result;
				const idx = MiscTools.findIndex(this.billingCodes, returnedBC.id);
				if (idx !== -1) this.billingCodes[idx] = returnedBC;

				this.adminLogs = await this.adminLogsService.getLogs(['organization'], this.id);
				if (this.logsTable1)
					this.logsTable1.updateContent(this.adminLogs, 'cp-organization-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

				// for (let bc of this.billingCodes)
				// 	if (bc.id === returnedBC.id)
				// 		bc = returnedBC;
			} else {
				return false;
			}
		}
	}

	// *********************************************************
	async toggleFavorite() {
		this.spinFavorite = true;
		const result = await this.organizationsService.toggeleFavorite(this.organization.id);
		if (result) {
			this.organization = result;
			this.spinFavorite = false;
		} else {
			this.spinFavorite = false;
			return false;
		}
	}

	// *********************************************************
	getProduct(id: number) {
		return this.productsService.getOne(id);
	}

	// *********************************************************
	getPlatform(id: number) {
		return this.platformsService.getOne(id);
	}

	// *********************************************************
	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	// *********************************************************
	subListOfUsedProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (product.ptype === productType) {
				for (const pp of this.organization.product_platforms) {
					if (pp.product_id === product.id) {
						sublist.push(product);
						break;
					}
				}
			}
		}
		return sublist;
	}

	// *********************************************************
	getPlatformIdsForProduct(productId: number, productPlatforms: Models.ProductPlatform[]): number[] {
		const pids = [];
		for (const pp of productPlatforms)
			if (pp.product_id === productId)
				pids.push(pp.platform_id);
		return pids;
	}

	// *********************************************************
	subListOfProducts(productType: string) {
		const sublist: Models.Product[] = [];
		for (const product of this.allProducts) {
			if (product.ptype === productType) {
				sublist.push(product);
			}
		}
		return sublist;
	}

	// *********************************************************
	subListOfPlatforms(productID: number) {
		const sublist: Models.Platform[] = [];
		for (const platform of this.allPlatforms) {
			if (this.findProductPlatform(this.allProductPlatforms, productID, platform.id) !== -1) {
				sublist.push(platform);
			}
		}
		return sublist;
	}

	// *********************************************************
	findProductPlatform(arr: Models.ProductPlatform[], productID: number, platformID: number): number {
		for (const idx in arr) {
			if (arr[idx].product_id === productID && arr[idx].platform_id === platformID) {
				return +idx;
			}
		}
		return -1;
	}

	// *********************************************************
	getUserIssues(user: Models.User) {
		return ValidationTools.getUserIssues(user, true).join(' ');
	}

	// *********************************************************
	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].label;
	}

	// *********************************************************
	openKeyReport(metricType: string) {
		let url = AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + this.id + '/key-report';
		url += '?metricType=' + encodeURIComponent(metricType);
		window.open(url, '_blank');
	}

	// *********************************************************
	async getSalesforceInfoBlocks() {
		this.sfFetching = true;
		if (this.organization && this.organization.salesforce_account_id && this.organization.salesforce_account_id !== '')
			this.salesforceAccountInfoBlock = await this.organizationsService.makeSalesForceObjectBlock('accounts', this.organization.salesforce_account_id);
		this.sfFetching = false;
	}

	// *********************************************************
	toggleWarnings() {
		this.expandWarnings = !this.expandWarnings;
		if (this.keysTable1) this.keysTable1.toggleWarnings();
		if (this.keysTable2) this.keysTable2.toggleWarnings();
		localStorage.setItem('organizations.keys.expandWarnings', this.expandWarnings.toString());
	}

	// *********************************************************
	runKeySearchForOrganization() {
		const searchSettings: Models.KeySearchSettings = new Models.KeySearchSettings();
		searchSettings.orgIDs = [this.id];
		localStorage.setItem('licensingSearch.searchOptions', JSON.stringify(searchSettings));
		sessionStorage.setItem('licensingSearch.autoRun', 'yes');
		this.router.navigate(['/' + AppConstants.urls.licensing]);
	}

	// *********************************************************
	doAggregate() {
		let keyList = '';
		for (const act of this.keys)
			keyList += act.key + '\n';
		for (const act of this.keys2)
			keyList += act.key + '\n';

		localStorage.setItem('licensing.keysAndHostIds', keyList);
		this.router.navigate(['/' + AppConstants.urls.licensing, 'aggregate']);
	}

	// *********************************************************
	runKeySearchForBillingCodes() {
		const codes: string[] = [];
		for (const bc of this.billingCodes)
			codes.push(bc.billing_code);

		const searchSettings: Models.KeySearchSettings = new Models.KeySearchSettings();
		searchSettings.billingCodeFilter = codes;
		localStorage.setItem('licensingSearch.searchOptions', JSON.stringify(searchSettings));
		sessionStorage.setItem('licensingSearch.autoRun', 'yes');
		this.router.navigate(['/' + AppConstants.urls.licensing]);
	}

	// *********************************************************
	async kickOffOrgMeterReport(startDate: Date = null, endDate: Date = null) {
		try {
			// this.saving = true;
			const resp = await this.organizationsService.kickOffOrgMeterReport(this.id, startDate, endDate);
			this.uiAlertsService.addMsg('Your report is being generated and you will receive it in the next 10 minutes.', 'success', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

			// this.saving = false;
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// *********************************************************
	async kickOffOrgProtocolReport(startDate: Date = null, endDate: Date = null) {
		try {
			// this.saving = true;
			const resp = await this.organizationsService.kickOffOrgProtocolReport(this.id, startDate, endDate);
			this.uiAlertsService.addMsg('Your report is being generated and you will receive it in the next 10 minutes.', 'success', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			// this.saving = false;
		} catch (e) {
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	// *********************************************************
	openBillingCodeReport() {
		let url = AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + this.id + '/billing-codes/report';
		window.open(url, '_blank');
	}

	// *********************************************************
	getUsersName(id: number): string {
		return this.usersService.getUsersName(id);
	}

	// *********************************************************
	async runDownloadsReport() {
		await this.reportsService.runReport('AllDownloadsReport', 'objType=organization&objId=' + this.id);
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

	// --------------------------------------------------------------------
	getKey(keyId: number) {
		let theKey = MiscTools.pickItem(this.keys, 'id', keyId);
		if (!theKey) theKey = MiscTools.pickItem(this.keys2, 'id', keyId);
		if (theKey)
			return theKey.key;
		else
			return '????';
	}

	// *********************************************************
	async runPenTestOnIPs(ips: string[], username: string) {
		this.penTestRunning = true;

		try {
			await this.licensingService.runPenTestOnIPs(ips, username);

			this.allPenTests = await this.organizationsService.getOrganizationPenTests(this.id);

			const tmpFailed: Models.BroadcasterPenTest[] = [];
			for (const p of this.allPenTests)
				if (p.test_code === 0)
					tmpFailed.push(p);

			this.failedPenTests = tmpFailed;
			this.uiAlertsService.addMsg('Testing finished.', 'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} catch (e) {
			if (e.message)
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			else
				this.uiAlertsService.addMsg('There was a problem', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}

		this.penTestRunning = false;
	} //

	// *********************************************************
	async runPenTestReport() {
		await this.reportsService.runReport('PenTestResultsReport', 'orgId=' + this.id);
	}

	// *********************************************************
	openAccountReview() {
		let url = AppConstants.apiUrl + AppConstants.apiUrls.organizations + '/' + this.id + '/account-review';
		window.open(url, '_blank');
	}

}
