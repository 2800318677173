import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { ZenCustomersService } from '../zen-customers.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { OrganizationsService } from '../../organizations/organizations.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-search-incidents',
	templateUrl: './search-incidents.component.html',
	styleUrls: ['./search-incidents.component.scss']
})
export class SearchIncidentsComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;
	miscTools = MiscTools;
	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	items: Models.ZenIncident[];
	itemsToShow: Models.ZenIncident[];

	loading = true;
	refreshing = false;

	// other stuff...
	maxMessage = '';
	showFilters = false;
	theForm: UntypedFormGroup;

	seIds: number[] = [];
	states: string[] = [];
	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;

	private userSubscription: Subscription;
	stateSelections: any[] = [];
	seSelections: any[] = [];

	users: Models.User[] = [];
	organizations: Models.Organization[] = [];
	zenCustomers: Models.ZenMasterCustomer[] = [];

	zenNames: any = {};
	zenPrefixes: any = {};
	zenCustomerOrgIds: any = {};
	zenCustomerOrgNames: any = {};
	zenCustomerSENames: any = {};
	zenCustomerSEIds: any = {};

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-incidents-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: '__zenname',
			type: 'baseText',
			sortType: 'text',
			label: 'ZM Name',
			toolTip: null,
			icon: null
		}, {
			field: '__se',
			type: 'baseText',
			sortType: 'text',
			label: 'SE',
			toolTip: null,
			icon: null
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'state',
			type: 'baseText',
			sortType: 'text',
			label: 'State',
			toolTip: null,
			icon: null
		}, {
			field: 'start_time',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Start Time',
			toolTip: null,
			icon: null
		}, {
			field: '__duration_num',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'Duration',
			toolTip: null,
			icon: null
		}, {
			field: '__runtime_state',
			type: 'baseText',
			sortType: 'text',
			label: 'Runtime State',
			toolTip: null,
			icon: null
		}, {
			field: 'likely_cause',
			type: 'baseText',
			sortType: 'text',
			label: 'Root Cause',
			toolTip: null,
			icon: null
		}, {
			field: 'triggering_error_message',
			type: 'baseText',
			sortType: 'text',
			label: 'Description',
			toolTip: null,
			icon: null
		}, {
			field: 'num_objects',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: '# Objs.',
			toolTip: null,
			icon: null
		}
	];

	constructor(
		private router: Router,
		private datePipe: DatePipe,
		private zenCustomersService: ZenCustomersService,
		private usersService: UsersService,
		private organizationsService: OrganizationsService,
		private authService: AuthService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const seIds = JSON.parse(localStorage.getItem('zenIncidents.seIds'));
		if (seIds) this.seIds = seIds;

		const states = JSON.parse(localStorage.getItem('zenIncidents.states'));
		if (states) this.states = states;

		const notBefore = JSON.parse(localStorage.getItem('zenIncidents.notBefore'));
		if (notBefore) this.notBefore = notBefore;

		const notAfter = JSON.parse(localStorage.getItem('zenIncidents.notAfter'));
		if (notAfter) this.notAfter = notAfter;

		this.showFilters = (this.states.length !== 0 || this.seIds.length !== 0 || notBefore != null || notAfter != null);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {
				this.setup();
			}
		});

	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	setup() {
		this.users = this.usersService.getAll();
		this.organizations = this.organizationsService.getAll();
		this.zenCustomers = this.zenCustomersService.getAll();

		this.stateSelections = [
			{ value: 'open', label: 'Open' },
			{ value: 'resolved', label: 'Resolved' },
			{ value: 'closed', label: 'Closed' },
		];

		this.zenNames = {};
		this.zenCustomerOrgIds = {};
		this.zenCustomerOrgNames = {};
		this.zenCustomerSENames = {};
		this.zenCustomerSEIds = {};
		this.zenPrefixes = {};

		const seIds: number[] = [];
		for (const zenCustomer of this.zenCustomers) {
			this.zenNames[zenCustomer.id] = zenCustomer.name;
			this.zenPrefixes[zenCustomer.id] = zenCustomer.dns_prefix;
			this.zenCustomerOrgIds[zenCustomer.id] = 0;
			this.zenCustomerOrgNames[zenCustomer.id] = '';
			this.zenCustomerSENames[zenCustomer.id] = '';
			this.zenCustomerSEIds[zenCustomer.id] = 0;

			if (zenCustomer.zcp_org_id && zenCustomer.zcp_org_id !== 0) {
				const idx = MiscTools.findIndex(this.organizations, zenCustomer.zcp_org_id);
				if (idx !== -1) {
					this.zenCustomerOrgIds[zenCustomer.id] = zenCustomer.zcp_org_id;
					this.zenCustomerOrgNames[zenCustomer.id] = this.organizations[idx].name;
					if (this.organizations[idx].salesforce_se_id && this.organizations[idx].salesforce_se_id !== 0) {
						this.zenCustomerSENames[zenCustomer.id] = this.organizations[idx].salesforce_se;
						this.zenCustomerSEIds[zenCustomer.id] = this.organizations[idx].salesforce_se_id;
						if (!seIds.includes(this.organizations[idx].salesforce_se_id))
							seIds.push(this.organizations[idx].salesforce_se_id);
					} // if
				} // if
			} // if
		} // for

		this.seSelections = [];
		for (const user of this.users)
			if (seIds.includes(user.id) && user.is_enabled === 1)
				this.seSelections.push({
					id: user.id,
					name: user.name
				});

		this.theForm = new UntypedFormGroup({
			seIds: new UntypedFormControl(this.seIds),
			states: new UntypedFormControl(this.states),
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});

		this.loadIncidents();
	}

	// ------------------------------------------------------------------------
	async loadIncidents() {
		this.loading = true;

		this.now = new Date();

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		const customerIds: number[] = [];

		if (this.seIds && this.seIds.length > 0) {
			for (const zenCustomer of this.zenCustomers)
				if (this.zenCustomerSEIds[zenCustomer.id] && this.seIds.includes(this.zenCustomerSEIds[zenCustomer.id]))
					customerIds.push(zenCustomer.id);
		} // if

		this.items = await this.zenCustomersService.getIncidents(customerIds, this.states, startDate, endDate);

		if (this.items && this.items.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum incidents (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of incidents retrieved and/or expand the timeframe.';
		else
			this.maxMessage = '';

		for (const item of this.items) {
			item['__zenname'] = this.zenNames[item.customer_id];
			item['__se'] = TextTools.acronym(this.zenCustomerSENames[item.customer_id]);
			item['__url'] = this.makeIncidentUrl(item);
			item['__duration_num'] = this.getDuration(item, true);
			item['__duration_str'] = this.getDuration(item, false);

			item['__runtime_state'] = this.getRuntimeState(item);

		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);


		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'start_time', 'desc');
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.states);
		this.seIds = this.theForm.value.seIds;
		this.states = this.theForm.value.states;
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		localStorage.setItem('zenIncidents.seIds', JSON.stringify(this.seIds));
		localStorage.setItem('zenIncidents.states', JSON.stringify(this.states));
		localStorage.setItem('zenIncidents.notBefore', JSON.stringify(this.notBefore));
		localStorage.setItem('zenIncidents.notAfter', JSON.stringify(this.notAfter));

		this.loadIncidents();
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	getDuration(incident: Models.ZenIncident, asNumber: boolean): any {
		const startAt = new Date(incident.start_time);
		let endAt = this.now;
		if (incident.end_time != null)
			endAt = new Date(incident.end_time);

		const durSecs = Math.floor((endAt.getTime() - startAt.getTime()) / 1000);

		if (asNumber)
			return durSecs;

		let dur = TextTools.formattedDuration(durSecs, false);

		dur = dur.replace(' days', 'd,');
		dur = dur.replace(' day', 'd,');
		dur = dur.replace(' hours', 'h,');
		dur = dur.replace(' hour', 'h,');
		dur = dur.replace(' minutes', 'm,');
		dur = dur.replace(' minute', 'm,');
		dur = dur.replace(' seconds', 's,');
		dur = dur.replace(' second', 's,');

		if (dur.endsWith(',')) dur = dur.substring(0, dur.length - 1);

		// if (incident.end_time == null) dur += ' +';

		return dur;
	} //

	// ------------------------------------------------------------------------
	getRuntimeState(incident: Models.ZenIncident): string {
		if (incident.end_time != null)
			return 'Ended';
		return 'Ongoing';
	} //

	// ------------------------------------------------------------------------
	makeIncidentUrl(incident: Models.ZenIncident): string {
		const url = AppConstants.zenLoginUrlTemplate.replace(AppConstants.zenTemplateToken, this.zenPrefixes[incident.customer_id])
			+ 'incidents/' + incident.id;
		return url;
	} //

	// ************************************************************************************************
	incidentPopoverLines(incident: Models.ZenIncident): string[] {
		if (!incident) return [];

		const startAt = new Date(incident.start_time);
		let endAt = this.now;
		if (incident.end_time != null)
			endAt = new Date(incident.end_time);

		const durSecs = Math.floor((endAt.getTime() - startAt.getTime()) / 1000);

		const lines: string[] = [];
		lines.push('Site Name: ' + this.zenNames[incident.customer_id]);
		lines.push('DNS Prefix: ' + this.zenPrefixes[incident.customer_id]);
		if (this.zenCustomerOrgNames[incident.customer_id] && this.zenCustomerOrgNames[incident.customer_id] !== '')
			lines.push('Organization: ' + this.zenCustomerOrgNames[incident.customer_id]);
		if (this.zenCustomerSENames[incident.customer_id] && this.zenCustomerSENames[incident.customer_id] !== '')
			lines.push('Sales Engineer: ' + this.zenCustomerSENames[incident.customer_id]);
		lines.push('Name: ' + incident.name);
		lines.push('State: ' + TextTools.capitalizeFirstLetter(incident.state));

		// lines.push('Start Time: ' + TextTools.formatDateTimeNiceUTC(incident.start_time) + ' UTC');
		// lines.push('End Time: ' + TextTools.formatDateTimeNiceUTC(incident.end_time) + ' UTC');
		lines.push('Start Time: ' + this.datePipe.transform(incident.start_time, AppConstants.pageDateTimeFmt));
		if (incident.end_time)
			lines.push('End Time: ' + this.datePipe.transform(incident.end_time, AppConstants.pageDateTimeFmt));

		lines.push('Duration: ' + TextTools.formattedDuration(durSecs, false));
		lines.push('Runtime State: ' + this.getRuntimeState(incident));
		lines.push('Root Cause: ' + incident.likely_cause);
		lines.push('Description: ' + incident.triggering_error_message);
		lines.push('# Objects: ' + incident.num_objects);

		return lines;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.ZenIncident[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| (item.state && item.state.toLowerCase().includes(w))
						|| (item.likely_cause && item.likely_cause.toLowerCase().includes(w))
						|| (item.triggering_error_message && item.triggering_error_message.toLowerCase().includes(w))
						|| (item['__zenname'] && item['__zenname'].toLowerCase().includes(w))
						|| (item['__se'] && item['__se'].toLowerCase().includes(w))
						|| (item['__runtime_state'] && item['__runtime_state'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}




}

