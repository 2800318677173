import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';
import PopOverTools from 'appshared/popover-tools';

import TrackRecent from '../../../../helpers/track-recent';

import { ZenCustomersService } from '../zen-customers.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { AuthService } from 'client/app/services/auth.service';
import { ProductsService } from '../../products/products.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-zen-customers-list',
	templateUrl: './zen-customers-list.component.html',
	styleUrls: ['./zen-customers-list.component.scss']
})
export class ZenCustomersListComponent implements OnInit {
	appConstants = AppConstants;
	textTools = TextTools;
	popOverTools = PopOverTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	items: Models.ZenMasterCustomer[] = [];
	itemsToShow: Models.ZenMasterCustomer[] = [];

	loading = true;
	organizations: Models.Organization[] = [];

	canAdd = false;

	// others
	showFilters = false;
	theForm: UntypedFormGroup;

	// things to choose from
	enabledChoices = [];
	assessmentChoices = [];
	zmTypeChoices = [];
	recentTrafficChoices = [];
	activeBxChoices = [];

	accountOwnerChoices: any[] = [];
	techRepChoices: any[] = [];

	specialChoices: any[] = [
		{ value: 'account_owner', label: 'I am the account owner' },
		{ value: 'tech_rep', label: 'I am a tech rep' },
		{ value: 'not_active', label: 'Site appears to be inactive' },
		{ value: 'no_notifications', label: 'Not setup for portal notifications' },
	];

	// filters
	enabledFilter: number = null;
	assessmentFilters = [];
	zmTypeFilters = [];
	recentTrafficFilters = [];
	activeBxFilters = [];
	accountOwnerFilters: any[] = [];
	techRepFilters: any[] = [];
	specialFilters: any[] = [];

	cachingUsageInfo: string = '';

	// extraInfo: any = {};

	recentZenCustomers: Models.ZenMasterCustomer[] = [];

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-zenmasters-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: 'dns_prefix',
			type: 'baseText',
			sortType: 'text',
			label: 'Prefix',
			toolTip: null,
			icon: null
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: '__zenType',
			type: 'baseText',
			sortType: 'text',
			label: 'Type',
			toolTip: null,
			icon: null
		}, {
			field: 'assessment',
			type: 'baseText',
			sortType: 'text',
			label: 'Assessment',
			toolTip: null,
			icon: null
		}, {
			field: '__notifyWarning',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'At Least One User Will Get ZEN Master Notifications from ZCP',
			icon: AppConstants.sectionIcons.mynotifications
		}, {
			field: '__orgName',
			type: 'baseText',
			sortType: 'text',
			label: 'Organization',
			toolTip: null,
			icon: null
		}, {
			field: '__accountOwnerShort',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Account Owner',
			icon: ['fab', 'salesforce']
		}, {
			field: '__seShort',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'SE/Tech-Rep',
			icon: 'user-graduate'
		}, {
			field: 'created_days',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days since added',
			icon: 'clock'
		}, {
			field: 'last_login_days',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days since last login',
			icon: 'sign-in-alt'
		}, {
			field: '__zenUsage',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'ZEN Master (Non-MediaConnect) Usage (Last ' + AppConstants.assessmentThresholdDays + ' Days)',
			icon: 'chart-line'
		}, {
			field: '__mcUsage',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'ZEN Master (MediaConnect) Usage (Last ' + AppConstants.assessmentThresholdDays + ' Days)',
			icon: 'chart-line'
		}, {
			field: 'num_active_bx',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Active Broadcasters',
			icon: 'bolt'
		}, {
			field: '__marketplace',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Marketplace',
			icon: AppConstants.sectionIcons.marketplace
		}
	];

	constructor(
		private router: Router,
		private authService: AuthService,
		private zenCustomersService: ZenCustomersService,
		private usersService: UsersService,
		private productsService: ProductsService,
		private organizationsService: OrganizationsService) {


	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;

			const enabledFilter = JSON.parse(localStorage.getItem('zenCustomers.enabledFilter'));
			if (enabledFilter !== null) this.enabledFilter = +enabledFilter;

			const assessmentFilters = JSON.parse(localStorage.getItem('zenCustomers.assessmentFilters'));
			if (assessmentFilters) this.assessmentFilters = assessmentFilters;

			const zmTypeFilters = JSON.parse(localStorage.getItem('zenCustomers.zmTypeFilters'));
			if (zmTypeFilters) this.zmTypeFilters = zmTypeFilters;

			const recentTrafficFilters = JSON.parse(localStorage.getItem('zenCustomers.recentTrafficFilters'));
			if (recentTrafficFilters) this.recentTrafficFilters = recentTrafficFilters;

			const activeBxFilters = JSON.parse(localStorage.getItem('zenCustomers.activeBxFilters'));
			if (activeBxFilters) this.activeBxFilters = activeBxFilters;

			const accountOwnerFilters = JSON.parse(localStorage.getItem('zenCustomers.accountOwnerFilters'));
			if (accountOwnerFilters) this.accountOwnerFilters = accountOwnerFilters;

			const techRepFilters = JSON.parse(localStorage.getItem('zenCustomers.techRepFilters'));
			if (techRepFilters) this.techRepFilters = techRepFilters;

			const specialFilters = JSON.parse(localStorage.getItem('zenCustomers.specialFilters'));
			if (specialFilters) this.specialFilters = specialFilters;

			this.showFilters = (
				!!this.enabledFilter
				|| this.assessmentFilters.length !== 0
				|| this.zmTypeFilters.length !== 0
				|| this.recentTrafficFilters.length !== 0
				|| this.activeBxFilters.length !== 0
				|| this.accountOwnerFilters.length !== 0
				|| this.techRepFilters.length !== 0
				|| this.specialFilters.length !== 0
			);

			this.loadData();
		});
	}

	// ------------------------------------------------------------------------
	async loadData(forceReload = false) {

		this.loading = true;

		if (forceReload) {
			this.zenCustomersService.refreshAll();
			this.organizationsService.refreshAll();
			await MiscTools.delay(3000);
		} // if

		this.items = this.zenCustomersService.getAll();
		this.organizations = this.organizationsService.getAll();

		const recentIds = TrackRecent.getRecent('zenmaster');
		recentIds.reverse();

		this.recentZenCustomers = [];
		for (const recentId of recentIds) {
			const zm = this.zenCustomersService.getOne(recentId);
			if (zm) this.recentZenCustomers.push(zm);
		} // for

		this.canAdd = ValidationTools.checkAccess(this.authUser, 'admin-zen-master');

		const allUsers = this.usersService.getAll();
		const allProducts = this.productsService.getAll();
		let zenID = -1;
		for (const prod of allProducts)
			if (prod.name === 'ZEN Master')
				zenID = prod.id;

		let zenOrgIDs: number[] = [];
		if (zenID !== -1) {
			const allOrgs = this.organizationsService.getAll();
			for (const org of allOrgs)
				if (MiscTools.findIndexGeneric(org.product_platforms, 'product_id', zenID) !== -1)
					zenOrgIDs.push(org.id);
		} // if

		const zenUsers = await this.zenCustomersService.getZenUsers();

		// this.extraInfo = {};
		let usageCached: boolean = false;
		for (const item of this.items) {
			let orgName = '';
			let accountOwnerName = '';
			let accountSEName = '';

			if (item.zcp_org_id && item.zcp_org_id !== 0) {
				const org = this.organizationsService.getOne(item.zcp_org_id);
				if (org) {
					orgName = org.name;
					accountOwnerName = org.salesforce_account_owner;
					accountSEName = org.salesforce_se;
				} // if
			} // if

			let subscribedCount = 0;
			for (const user of zenUsers) {
				if (user.customer_id === item.id) {
					const notifyInfo = ValidationTools.getZenUserNotificationState(allUsers, zenOrgIDs, user);
					if (notifyInfo === 'Subscribed') subscribedCount++;
				} // if
			} // for

			const notifyWarning: boolean = subscribedCount === 0;

			// this.extraInfo[item.id] = {
			// 	orgName,
			// 	accountOwnerName,
			// 	shortAccountOwnerName: TextTools.acronym(accountOwnerName),
			// 	accountSEName,
			// 	shortAccountSEName: TextTools.acronym(accountSEName),
			// 	notifyWarning
			// };

			item['__zenType'] = this.getZenType(item);
			item['__notifyWarning'] = notifyWarning;
			item['__orgName'] = orgName;
			item['__accountOwner'] = accountOwnerName;
			item['__accountOwnerShort'] = TextTools.acronym(accountOwnerName);
			item['__se'] = accountSEName;
			item['__seShort'] = TextTools.acronym(accountSEName);
			item['__zenUsage'] = this.getUsage(item, 'zenmaster');
			item['__mcUsage'] = this.getUsage(item, 'mediaconnect');
			item['__marketplace'] = this.getMarketplace(item);

			if (isNaN(item['__zenUsage'])) item['__zenUsage'] = 0;
			if (isNaN(item['__mcUsage'])) item['__mcUsage'] = 0;

			if (+item['__zenUsage'] !== 0 || +item['__mcUsage'] !== 0) usageCached = true;
		} // for

		this.zmTypeChoices = [];
		this.zmTypeChoices.push({ value: '', label: 'Unknown/Not Set' });
		for (const zenMasterType of AppConstants.zenMasterTypes)
			this.zmTypeChoices.push({ value: zenMasterType, label: AppConstants.zenMasterTypeLabels[zenMasterType] });

		this.assessmentChoices = [];
		for (const zenMasterAssessment of AppConstants.zenMasterAssessments)
			this.assessmentChoices.push({ value: zenMasterAssessment });

		this.enabledChoices = [];
		this.enabledChoices.push({ value: 1, label: 'Enabled/Online' });
		this.enabledChoices.push({ value: 0, label: 'Disabled/Offline' });

		this.recentTrafficChoices = [];
		this.recentTrafficChoices.push({ value: 1, label: 'Has Traffic in Last ' + AppConstants.assessmentThresholdDays + ' Days' });
		this.recentTrafficChoices.push({ value: 0, label: 'No Traffic in Last ' + AppConstants.assessmentThresholdDays + ' Days' });

		this.activeBxChoices = [];
		this.activeBxChoices.push({ value: 1, label: 'Has at Least One Active Bx' });
		this.activeBxChoices.push({ value: 0, label: 'No Active Bx' });

		this.accountOwnerChoices = [];
		this.techRepChoices = [];
		for (const org of this.organizations) {
			if (org.salesforce_account_owner_id && org.salesforce_account_owner_id !== 0) {
				const idx1 = MiscTools.findIndexGeneric(this.accountOwnerChoices, 'value', org.salesforce_account_owner);
				if (idx1 === -1)
					this.accountOwnerChoices.push({ value: org.salesforce_account_owner, label: org.salesforce_account_owner });
			} // if

			if (org.salesforce_se_id && org.salesforce_se_id !== 0) {
				const idx1 = MiscTools.findIndexGeneric(this.techRepChoices, 'value', org.salesforce_se);
				if (idx1 === -1)
					this.techRepChoices.push({ value: org.salesforce_se, label: org.salesforce_se });
			} // if
		} // for

		this.accountOwnerChoices.sort((a, b) => (a.label > b.label) ? 1 : -1);
		this.techRepChoices.sort((a, b) => (a.label > b.label) ? 1 : -1);

		this.theForm = new UntypedFormGroup({
			enabledFilter: new UntypedFormControl(this.enabledFilter),
			assessmentFilters: new UntypedFormControl(this.assessmentFilters),
			zmTypeFilters: new UntypedFormControl(this.zmTypeFilters),
			recentTrafficFilters: new UntypedFormControl(this.recentTrafficFilters),
			activeBxFilters: new UntypedFormControl(this.activeBxFilters),
			accountOwnerFilters: new UntypedFormControl(this.accountOwnerFilters),
			techRepFilters: new UntypedFormControl(this.techRepFilters),
			specialFilters: new UntypedFormControl(this.specialFilters)
		});

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'prefix', 'asc');

		if (!usageCached && this.items.length > 0) {
			this.cachingUsageInfo = 'Caching Usage Info';

			await this.zenCustomersService.updateKeyUsage();

			for (const item of this.items) {
				item['__zenUsage'] = await this.getUsage(item, 'zenmaster');
				item['__mcUsage'] = await this.getUsage(item, 'mediaconnect');
			} // for

			this.setSortBy(null);
			this.cachingUsageInfo = '';
		} // if

		// cache the stats blocks...
		for (const mode of AppConstants.zenMasterReportModes)
			await this.zenCustomersService.getZenCustomerInfo(mode, forceReload);

	}

	// ------------------------------------------------------------------------
	async getUsage(zc: Models.ZenMasterCustomer, type: string) {
		return this.zenCustomersService.getUsageNoLoad(zc, type);
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.enabledFilter = this.theForm.value.enabledFilter;
		this.assessmentFilters = this.theForm.value.assessmentFilters;
		this.zmTypeFilters = this.theForm.value.zmTypeFilters;
		this.recentTrafficFilters = this.theForm.value.recentTrafficFilters;
		this.activeBxFilters = this.theForm.value.activeBxFilters;
		this.accountOwnerFilters = this.theForm.value.accountOwnerFilters;
		this.techRepFilters = this.theForm.value.techRepFilters;
		this.specialFilters = this.theForm.value.specialFilters;

		localStorage.setItem('zenCustomers.enabledFilter', JSON.stringify(this.enabledFilter));
		localStorage.setItem('zenCustomers.assessmentFilters', JSON.stringify(this.assessmentFilters));
		localStorage.setItem('zenCustomers.zmTypeFilters', JSON.stringify(this.zmTypeFilters));
		localStorage.setItem('zenCustomers.recentTrafficFilters', JSON.stringify(this.recentTrafficFilters));
		localStorage.setItem('zenCustomers.activeBxFilters', JSON.stringify(this.activeBxFilters));
		localStorage.setItem('zenCustomers.accountOwnerFilters', JSON.stringify(this.accountOwnerFilters));
		localStorage.setItem('zenCustomers.techRepFilters', JSON.stringify(this.techRepFilters));
		localStorage.setItem('zenCustomers.specialFilters', JSON.stringify(this.specialFilters));

		this.setSortBy(null);
	}


	// ------------------------------------------------------------------------
	getZenType(zc: Models.ZenMasterCustomer) {
		if (zc.zcp_type && zc.zcp_type !== '')
			return AppConstants.zenMasterTypeLabels[zc.zcp_type];
		return '';
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	displayDays(numDays: number) {
		if (numDays == null) return 'Never';

		const numYears = Math.abs(numDays / 365.25);
		if (numYears > 1.25)
			return numYears.toFixed(1) + ' years';

		return numDays.toLocaleString() + ' day' + (numDays > 1 ? 's' : '');
	}

	// ------------------------------------------------------------------------
	getMarketplace(zc: Models.ZenMasterCustomer) {
		if (zc.marketplace != null && zc.marketplace.marketplace !== '')
			return zc.marketplace.marketplace;
		return '';
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.ZenMasterCustomer[] = [];

		for (const item of this.items) {
			let passedEnabledFilter = false;
			let passedTypeFilter = false;
			let passedAssessmentFilter = false;
			let passedRecentTrafficFilter = false;
			let pasedActiveBxFilter = false;
			let passedAccountOwnerFilters = false;
			let passedTechRepFilters = false;
			let passedSpecialFilters = false;

			if (this.enabledFilter == null || this.enabledFilter === item.is_enabled)
				passedEnabledFilter = true;

			if (!this.zmTypeFilters || this.zmTypeFilters.length === 0
				|| (this.zmTypeFilters.length !== 0 && item.zcp_type && this.zmTypeFilters.includes(item.zcp_type)))
				passedTypeFilter = true;

			if (this.zmTypeFilters.length !== 0 && this.zmTypeFilters.includes('') && (!item.zcp_type || item.zcp_type === ''))
				passedTypeFilter = true;

			if (!this.assessmentFilters || this.assessmentFilters.length === 0 || this.assessmentFilters.includes(item.assessment))
				passedAssessmentFilter = true;

			const totalUsage: number = this.zenCustomersService.getUsageNoLoad(item, 'zenmaster')
				+ this.zenCustomersService.getUsageNoLoad(item, 'mediaconnect');

			if (!this.recentTrafficFilters || this.recentTrafficFilters.length === 0
				|| (this.recentTrafficFilters.includes(1) && totalUsage > 0) || (this.recentTrafficFilters.includes(0) && totalUsage === 0))
				passedRecentTrafficFilter = true;

			if (!this.activeBxFilters || this.activeBxFilters.length === 0
				|| (this.activeBxFilters.includes(1) && item.num_active_bx > 0)
				|| (this.activeBxFilters.includes(0) && item.num_active_bx === 0)
			)
				pasedActiveBxFilter = true;

			if (!this.accountOwnerFilters || this.accountOwnerFilters.length === 0 || this.accountOwnerFilters.includes(item['__accountOwner']))
				passedAccountOwnerFilters = true;

			if (!this.techRepFilters || this.techRepFilters.length === 0 || this.techRepFilters.includes(item['__se']))
				passedTechRepFilters = true;

			if (!this.specialFilters || this.specialFilters.length === 0) {
				passedSpecialFilters = true;
			} else {
				passedSpecialFilters = true;

				if (this.specialFilters.includes('account_owner') && this.authUser && item['__accountOwner'] !== this.authUser.name)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('tech_rep') && this.authUser && item['__se'] !== this.authUser.name)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('not_active') && (item.is_enabled !== 1 || !AppConstants.unusedZENMasterAssessments.includes(item.assessment) || totalUsage !== 0))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_notifications') && !item['__notifyWarning'])
					passedSpecialFilters = false;
			}

			let match: boolean = (passedEnabledFilter && passedTypeFilter && passedAssessmentFilter && passedRecentTrafficFilter
				&& pasedActiveBxFilter && passedAccountOwnerFilters && passedTechRepFilters && passedSpecialFilters);

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.dns_prefix.toLowerCase().includes(w)
						|| (item.name && item.name.toLowerCase().includes(w))
						|| (item.assessment && item.assessment.toLowerCase().includes(w))
						|| (item['__zenType'] && item['__zenType'].toLowerCase().includes(w))
						|| (item['__orgName'] && item['__orgName'].toLowerCase().includes(w))
						|| (item['__accountOwner'] && item['__accountOwner'].toLowerCase().includes(w))
						|| (item['__accountOwnerShort'] && item['__accountOwnerShort'].toLowerCase().includes(w))
						|| (item['__se'] && item['__se'].toLowerCase().includes(w))
						|| (item['__seShort'] && item['__seShort'].toLowerCase().includes(w))
						|| (item['__runtime_state'] && item['__runtime_state'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

	// getMarketplaceIcon(zc: Models.ZenMasterCustomer) {
	// 	if (zc.marketplace != null && zc.marketplace.marketplace !== '') {
	// 		const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', zc.marketplace.marketplace);
	// 		if (idx1 !== -1) return AppConstants.marketPlaceSelections[idx1].icon;
	// 	}
	// 	return null;
	// }
}
