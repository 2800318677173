import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { share } from 'rxjs/operators';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import { LicensingService } from '../licensing/licensing.service';


@Injectable({
	providedIn: 'root'
})
export class ZenCustomersService {
	zenmasters: Observable<Models.ZenMasterCustomer[]>;
	loaded = false;
	private debug = false;
	private _zenmasters: ReplaySubject<Models.ZenMasterCustomer[]>;
	private dataStore: {
		zenmasters: Models.ZenMasterCustomer[];
	};

	zenCustomersInfo: any = {};

	usageInfoStorageKey: string = 'zcp.zenusage.cache';
	usageInfo: any = {
		lastUpdate: null,
		resolvedKeys: {},
		keyUsage: {}
	};

	// resolvedKeys: any = {};
	// keyUsage: any = {};

	constructor(
		private http: HttpClient,
		private licensingService: LicensingService
	) {
		this.dataStore = {
			zenmasters: []
		};
		this._zenmasters = new ReplaySubject(1) as ReplaySubject<Models.ZenMasterCustomer[]>;
		this.zenmasters = this._zenmasters.asObservable();
	}

	// add/update an item in the datastore
	private updateStore(newZenMaster: Models.ZenMasterCustomer): void {
		const idx = this.dataStore.zenmasters.findIndex(zenmaster => zenmaster.id === newZenMaster.id);
		if (idx === -1) {
			this.dataStore.zenmasters.push(newZenMaster);
			return;
		} else {
			this.dataStore.zenmasters[idx] = newZenMaster;
		}
	}

	// get a fresh copy of the entire set
	refreshAll(): Observable<Models.ZenMasterCustomer[]> {
		const zenmasters$ = this.http.get<Models.ZenMasterCustomer[]>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers).pipe(share());
		zenmasters$.subscribe(
			data => {
				const zenmasters: Models.ZenMasterCustomer[] = data;
				// remove ones from the store that aren't in the response (they've been deleted)
				this.dataStore.zenmasters.forEach((existingZenMaster, existingIndex) => {
					const newIndex = zenmasters.findIndex(zenmaster => zenmaster.id === existingZenMaster.id);
					if (newIndex === -1) this.dataStore.zenmasters.splice(existingIndex, 1);
				});
				// add/update all the ones that came back
				zenmasters.forEach(zenmaster => this.updateStore(zenmaster));
				this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
				this.loaded = true;
			},
			error => { /* console.log(error) */ }
		);
		return zenmasters$;
	}

	// get a fresh copy of a single item
	refreshOne(id: number): Observable<Models.ZenMasterCustomer> {
		const zenmaster$ = this.http.get<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + id).pipe(share());
		zenmaster$.subscribe(
			data => {
				const zenmaster: Models.ZenMasterCustomer = data;
				// add/update the one that came back
				this.updateStore(zenmaster);
				this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			},
			error => { /* console.log(error) */ }
		);
		return zenmaster$;
	}

	// return the whole list
	getAll(): Models.ZenMasterCustomer[] {
		return this.dataStore.zenmasters.slice();
	}

	// grab a single item from the datastore
	getOne(id: number): Models.ZenMasterCustomer {
		return this.dataStore.zenmasters.find(zenmaster => zenmaster.id === id);
	}

	// call back-end to update an item
	async updateOne(zenmaster: Models.ZenMasterCustomer) {
		try {
			const result = await this.http.put<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + zenmaster.id, zenmaster).toPromise();
			const returnedZenMaster: Models.ZenMasterCustomer = result;
			this.updateStore(returnedZenMaster);
			this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			return returnedZenMaster;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getZenCustomerInfo(mode: string, force = false) {
		try {
			if (force || !this.zenCustomersInfo[mode]) {
				const url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/info/' + encodeURIComponent(mode);
				const result = await this.http.get<any>(url).toPromise();
				this.zenCustomersInfo[mode] = result;
			}

			return this.zenCustomersInfo[mode];
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getZenUsers(id: number = 0) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers;
			if (id && id !== 0) url += '/' + id;
			url += '/users';
			const result = await this.http.get<Models.ZenMasterUser[]>(url).toPromise();
			const users = result;
			return users;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getZenUsersByEmail(email: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers
				+ '/users/' + encodeURIComponent(email);
			const result = await this.http.get<Models.ZenMasterUser[]>(url).toPromise();
			const users = result;
			return users;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getZenClusters() {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers;
			url += '/cloud-clusters';

			const result = await this.http.get<Models.ZenMasterCluster[]>(url).toPromise();
			const clusters = result;
			return clusters;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async searchObjects(searchTerm: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers;
			url += '/objects?search=' + encodeURIComponent(searchTerm);

			const result = await this.http.get<Models.ZenMasterObject[]>(url).toPromise();
			const objects = result;
			return objects;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getSources(searchTerm: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers;
			url += '/sources?search=' + encodeURIComponent(searchTerm);

			const result = await this.http.get<Models.ZenMasterSource[]>(url).toPromise();
			const sources = result;
			return sources;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getChannels(searchTerm: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers;
			url += '/channels?search=' + encodeURIComponent(searchTerm);

			const result = await this.http.get<Models.ZenMasterChannel[]>(url).toPromise();
			const sources = result;
			return sources;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getTargets(searchTerm: string) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers;
			url += '/targets?search=' + encodeURIComponent(searchTerm);

			const result = await this.http.get<Models.ZenMasterTarget[]>(url).toPromise();
			const sources = result;
			return sources;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async sendZenCustomerShutdownNotice(zenID: number) {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + zenID + '/send-shutdown-notice';
			const result = await this.http.put<any>(url, {}).toPromise();
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getUsageKeyID(zc: Models.ZenMasterCustomer, keyType: string, force: boolean = false) {
		if (!this.usageInfo
			|| !this.usageInfo.resolvedKeys || Object.keys(this.usageInfo.resolvedKeys).length === 0
			|| !this.usageInfo.keyUsage || Object.keys(this.usageInfo.keyUsage).length === 0
		) force = true;

		// console.log('getUsageKeyID ' + zc.dns_prefix + ' ' + keyType + ' f=' + force);

		if (force) await this.updateKeyUsage();

		let theKey = '';
		if (keyType === 'mediaconnect' && zc.aws_mediaconnect_usage_key && zc.aws_mediaconnect_usage_key !== '')
			theKey = zc.aws_mediaconnect_usage_key;
		else if (keyType === 'zenmaster' && zc.generic_zm_usage_key && zc.generic_zm_usage_key !== '')
			theKey = zc.generic_zm_usage_key;

		if (theKey !== '' && this.usageInfo.resolvedKeys[theKey])
			return +this.usageInfo.resolvedKeys[theKey];

		return 0;
	} // 

	async getUsage(zc: Models.ZenMasterCustomer, keyType: string, force: boolean = false) {
		if (!this.usageInfo
			|| !this.usageInfo.resolvedKeys || Object.keys(this.usageInfo.resolvedKeys).length === 0
			|| !this.usageInfo.keyUsage || Object.keys(this.usageInfo.keyUsage).length === 0
		) force = true;

		// console.log('getUsage ' + zc.dns_prefix + ' ' + keyType + ' f=' + force);

		if (force) await this.updateKeyUsage();

		let theKey = '';
		if (keyType === 'mediaconnect' && zc.aws_mediaconnect_usage_key && zc.aws_mediaconnect_usage_key !== '')
			theKey = zc.aws_mediaconnect_usage_key;
		else if (keyType === 'zenmaster' && zc.generic_zm_usage_key && zc.generic_zm_usage_key !== '')
			theKey = zc.generic_zm_usage_key;

		if (theKey !== '' && this.usageInfo.resolvedKeys[theKey]) {
			const id = +this.usageInfo.resolvedKeys[theKey];
			const idx1 = MiscTools.findIndexGeneric(this.usageInfo.keyUsage, 'activation_id', id);
			if (idx1 !== -1) return this.usageInfo.keyUsage[idx1].total;
		} // if

		return 0;
	} // 

	getUsageNoLoad(zc: Models.ZenMasterCustomer, keyType: string) {
		// console.log('getUsageNoLoad ' + zc.dns_prefix + ' ' + keyType);

		if (!this.usageInfo
			|| !this.usageInfo.resolvedKeys || Object.keys(this.usageInfo.resolvedKeys).length === 0
			|| !this.usageInfo.keyUsage || Object.keys(this.usageInfo.keyUsage).length === 0
		) {
			try {
				if (sessionStorage.getItem(this.usageInfoStorageKey))
					this.usageInfo = JSON.parse(sessionStorage.getItem(this.usageInfoStorageKey));
			} catch (error) {
			}
		} // if

		if (!this.usageInfo
			|| !this.usageInfo.resolvedKeys || Object.keys(this.usageInfo.resolvedKeys).length === 0
			|| !this.usageInfo.keyUsage || Object.keys(this.usageInfo.keyUsage).length === 0
		) return 0;

		let theKey = '';
		if (keyType === 'mediaconnect' && zc.aws_mediaconnect_usage_key && zc.aws_mediaconnect_usage_key !== '')
			theKey = zc.aws_mediaconnect_usage_key;
		else if (keyType === 'zenmaster' && zc.generic_zm_usage_key && zc.generic_zm_usage_key !== '')
			theKey = zc.generic_zm_usage_key;

		if (theKey !== '' && this.usageInfo.resolvedKeys[theKey]) {
			const id = +this.usageInfo.resolvedKeys[theKey];
			const idx1 = MiscTools.findIndexGeneric(this.usageInfo.keyUsage, 'activation_id', id);
			if (idx1 !== -1) return this.usageInfo.keyUsage[idx1].total;
		} // if

		return 0;
	} // 

	async updateKeyUsage() {
		try {
			if (sessionStorage.getItem(this.usageInfoStorageKey))
				this.usageInfo = JSON.parse(sessionStorage.getItem(this.usageInfoStorageKey));
		} catch (error) {
		}

		if (this.usageInfo && this.usageInfo.lastUpdate) {
			const daysSince: number = MiscTools.daysSince(this.usageInfo.lastUpdate, false)
			// console.log('lastUpdate = ' + this.usageInfo.lastUpdate);
			// console.log('daysSince = ' + daysSince);

			if (daysSince > 0.25) { // if it's been more than 6 hours, get fresh data
				this.usageInfo.lastUpdate = null;
				this.usageInfo.resolvedKeys = {};
				this.usageInfo.keyUsage = {};
			} // if
		} // if

		if (this.usageInfo && this.usageInfo.resolvedKeys && Object.keys(this.usageInfo.resolvedKeys).length !== 0
			&& this.usageInfo.keyUsage && Object.keys(this.usageInfo.keyUsage).length !== 0) {
			// console.log('resolvedKeys = ' + Object.keys(this.usageInfo.resolvedKeys).length);
			// console.log('keyUsage = ' + Object.keys(this.usageInfo.keyUsage).length);

		} else {
			// console.log('getting usage');
			sessionStorage.removeItem(this.usageInfoStorageKey);

			try {
				const zenCustomers: Models.ZenMasterCustomer[] = this.getAll();
				const keysToResolve: string[] = [];
				for (const zc of zenCustomers) {
					if (zc.is_enabled === 1) {
						if (zc.aws_mediaconnect_usage_key && zc.aws_mediaconnect_usage_key !== '')
							keysToResolve.push(zc.aws_mediaconnect_usage_key);
						if (zc.generic_zm_usage_key && zc.generic_zm_usage_key !== '')
							keysToResolve.push(zc.generic_zm_usage_key);
					} // if
				} // if

				this.usageInfo.resolvedKeys = {};
				this.usageInfo.keyUsage = {};
				if (keysToResolve.length > 0) {
					this.usageInfo.resolvedKeys = await this.licensingService.resolveKeys(keysToResolve);

					const keyIDs: number[] = [];
					for (const key in this.usageInfo.resolvedKeys) {
						const id = +this.usageInfo.resolvedKeys[key];
						if (!isNaN(id) && id > 0) keyIDs.push(id);
					} // for

					if (keyIDs.length > 0)
						this.usageInfo.keyUsage = await this.licensingService.getTotalProtocolUsage(keyIDs);
				} // if

				this.usageInfo.lastUpdate = new Date();
				sessionStorage.setItem(this.usageInfoStorageKey, JSON.stringify(this.usageInfo));

				return;
			} catch (error) {
				console.log('Got an error during updateKeyUsage');
				MiscTools.handleBackendError(error);
			} // try

		} // if
	}

	// call back-end to update an item
	async adminAdd(zenmaster: Models.ZenMasterCustomer, adminPortalId: number, reason: string, region: string) {
		try {
			const result = await this.http.post<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers,
				{ zenmaster, adminPortalId, reason, region }).toPromise();
			const returnedZenMaster: Models.ZenMasterCustomer = result;
			this.updateStore(returnedZenMaster);
			this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			return returnedZenMaster;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async adminUpdate(zenmaster: Models.ZenMasterCustomer) {
		try {
			const result = await this.http.put<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + zenmaster.id + '/adminUpdate', zenmaster).toPromise();
			const returnedZenMaster: Models.ZenMasterCustomer = result;
			this.updateStore(returnedZenMaster);
			this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			return returnedZenMaster;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async adminDisable(id: number) {
		try {
			const result = await this.http.put<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + id + '/disable', {}).toPromise();
			const returnedZenMaster: Models.ZenMasterCustomer = result;
			this.updateStore(returnedZenMaster);
			this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			return returnedZenMaster;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async adminQuickEnable(id: number, reason: string) {
		try {
			const result = await this.http.put<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + id + '/quickEnable', { reason }).toPromise();
			const returnedZenMaster: Models.ZenMasterCustomer = result;
			this.updateStore(returnedZenMaster);
			this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			return returnedZenMaster;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	// async adminFullEnable(zenmaster: Models.ZenMasterCustomer) {
	// 	try {
	// 		const result = await this.http.put<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + zenmaster.id + '/fullEnable', zenmaster).toPromise();
	// 		const returnedZenMaster: Models.ZenMasterCustomer = result;
	// 		this.updateStore(returnedZenMaster);
	// 		this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
	// 		return returnedZenMaster;
	// 	} catch (error) {
	// 		MiscTools.handleBackendError(error);
	// 	}
	// }

	async adminDelete(id: number) {
		try {
			const result = await this.http.delete(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + id).toPromise();
			const idx = this.dataStore.zenmasters.findIndex(zenmaster => zenmaster.id === id);
			if (idx !== -1) this.dataStore.zenmasters.splice(idx, 1);
			this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			return true;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getIncidents(customerIds: number[] = [],
		states: string[] = [],
		startDate: Date = null,
		endDate: Date = null) {
		try {
			let url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/incidents?a=b';

			if (customerIds && customerIds.length !== 0) url += '&customerIds=' + encodeURIComponent(customerIds.join(','));
			if (states && states.length !== 0) url += '&states=' + encodeURIComponent(states.join(','));
			if (startDate && startDate !== null) url += '&startDate=' + encodeURIComponent(startDate + '');
			if (endDate && endDate !== null) url += '&endDate=' + encodeURIComponent(endDate + '');

			const result = await this.http.get<Models.ZenIncident[]>(url).toPromise();
			const arr: Models.ZenIncident[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async marketplaceUpdate(zenmaster: Models.ZenMasterCustomer) {
		try {
			const result = await this.http.put<Models.ZenMasterCustomer>(AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/' + zenmaster.id + '/marketplace', zenmaster.marketplace).toPromise();
			const returnedZenMaster: Models.ZenMasterCustomer = result;
			this.updateStore(returnedZenMaster);
			this._zenmasters.next(Object.assign({}, this.dataStore).zenmasters);
			return returnedZenMaster;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

	async getTunnelRegions() {
		try {
			const url = AppConstants.apiUrl + AppConstants.apiUrls.zencustomers + '/tunnel-regions';
			const result = await this.http.get<string[]>(url).toPromise();
			const arr: string[] = result;
			return arr;
		} catch (error) {
			MiscTools.handleBackendError(error);
		}
	}

}

