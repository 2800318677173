import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { ZenCustomersService } from '../zen-customers.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UsersService } from '../../users/users.service';
import { LicensingService } from '../../licensing/licensing.service';
import { ProductsService } from '../../products/products.service';

@Component({
	selector: 'app-zen-customers-report-staff',
	templateUrl: './zen-customers-report-staff.component.html',
	styleUrls: ['./zen-customers-report-staff.component.scss']
})
export class ZenCustomersReportStaffComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	staffMode = 'owner'; // or techrep

	zenCustomers: Models.ZenMasterCustomer[] = [];
	zenCustomersToShow: Models.ZenMasterCustomer[] = [];

	loading = true;
	// organizations: Models.Organization[] = [];

	showFilters = false;
	theForm: UntypedFormGroup;

	// things to choose from
	enabledChoices = [];
	assessmentChoices = [];
	zmTypeChoices = [];
	recentTrafficChoices = [];

	// filters
	enabledFilter: number = null;
	assessmentFilters = [];
	zmTypeFilters = [];
	recentTrafficFilters = [];

	reportData = {};
	people = [];
	noOwnerNoOrg = 'None - Site Not Linked to an Organization';
	noOwnerNoAcct = 'None - Organization Not Linked to Salesforce';

	noRepNoOrg = 'None - Site Not Linked to an Organization';
	noRepNotSet = 'None - No SE/Tech-Rep assigned';

	classifications: string[] = [];
	classificationLabels: any = {};

	accountOwnerFull = {};
	accountOwnerAbrevs = {};

	accountSEFull = {};
	accountSEAbrevs = {};

	notifyWarnings = {};

	constructor(
		private router: Router,
		private zenCustomersService: ZenCustomersService,
		private organizationsService: OrganizationsService,
		private licensingService: LicensingService,
		private productsService: ProductsService,
		private usersService: UsersService
	) { }

	ngOnInit(): void {
		const enabledFilter = JSON.parse(localStorage.getItem('zenStaffReport.enabledFilter'));
		if (enabledFilter !== null) this.enabledFilter = +enabledFilter;

		const assessmentFilters = JSON.parse(localStorage.getItem('zenStaffReport.assessmentFilters'));
		if (assessmentFilters) this.assessmentFilters = assessmentFilters;

		const zmTypeFilters = JSON.parse(localStorage.getItem('zenStaffReport.zmTypeFilters'));
		if (zmTypeFilters) this.zmTypeFilters = zmTypeFilters;

		if (localStorage.getItem('zenStaffReport.staffMode'))
			this.staffMode = localStorage.getItem('zenStaffReport.staffMode');

		const recentTrafficFilters = JSON.parse(localStorage.getItem('zenStaffReport.recentTrafficFilters'));
		if (recentTrafficFilters) this.recentTrafficFilters = recentTrafficFilters;

		this.showFilters = (
			!!this.enabledFilter
			|| this.assessmentFilters.length !== 0
			|| this.zmTypeFilters.length !== 0
			|| this.recentTrafficFilters.length !== 0
		);

		this.classifications = [];
		this.classificationLabels = {};
		for (const zenMasterType of AppConstants.zenMasterTypes) {
			this.classifications.push(zenMasterType + '-inactive');
			this.classifications.push(zenMasterType + '-active');
			this.classificationLabels[zenMasterType + '-inactive']
				= AppConstants.zenMasterTypeLabels[zenMasterType] + ': Inactive (No logins or none in last 30 days)';
			this.classificationLabels[zenMasterType + '-active']
				= AppConstants.zenMasterTypeLabels[zenMasterType] + ': Active';
		}
		this.classifications.push('disabled');
		this.classificationLabels['disabled'] = 'Disabled';

		this.initForm();
		this.loadZenObjects();
	}

	ngOnDestroy() {
	}

	toggleMode() {
		this.loading = true;
		if (this.staffMode === 'owner')
			this.staffMode = 'techrep';
		else
			this.staffMode = 'owner';
		localStorage.setItem('zenStaffReport.staffMode', this.staffMode);

		this.filterZens();
	}

	async loadZenObjects(forceReload = false) {
		this.loading = true;
		this.zenCustomers = this.zenCustomersService.getAll();
		// this.organizations = this.organizationsService.getAll();

		// make sure the key usage is cached
		if (this.zenCustomers.length > 0)
			await this.zenCustomersService.getUsageKeyID(this.zenCustomers[0], 'mediaconnect');

		for (const zc of this.zenCustomers) {
			if (zc.zcp_org_id && zc.zcp_org_id !== 0) {
				const org = this.organizationsService.getOne(zc.zcp_org_id);
				zc['' + 'organization'] = org;
			} // if
		} // if


		const allUsers = this.usersService.getAll();
		const allProducts = this.productsService.getAll();
		let zenID = -1;
		for (const prod of allProducts)
			if (prod.name === 'ZEN Master')
				zenID = prod.id;

		let zenOrgIDs: number[] = [];
		if (zenID !== -1) {
			const allOrgs = this.organizationsService.getAll();
			for (const org of allOrgs)
				if (MiscTools.findIndexGeneric(org.product_platforms, 'product_id', zenID) !== -1)
					zenOrgIDs.push(org.id);
		} // if

		const zenUsers = await this.zenCustomersService.getZenUsers();

		await this.zenCustomersService.updateKeyUsage();

		this.accountOwnerFull = {};
		this.accountOwnerAbrevs = {};

		this.accountSEFull = {};
		this.accountSEAbrevs = {};

		for (const zc of this.zenCustomers) {
			const owner = this.getOwner(zc);

			this.accountOwnerFull[zc.id] = '-';
			this.accountOwnerAbrevs[zc.id] = '-';

			if (owner !== this.noOwnerNoOrg && owner !== this.noOwnerNoOrg) {
				this.accountOwnerFull[zc.id] = owner;
				this.accountOwnerAbrevs[zc.id] = TextTools.acronym(owner);
			} // if

			const se = this.getSE(zc);

			this.accountSEFull[zc.id] = '-';
			this.accountSEAbrevs[zc.id] = '-';

			if (se !== this.noOwnerNoOrg && se !== this.noOwnerNoOrg) {
				this.accountSEFull[zc.id] = se;
				this.accountSEAbrevs[zc.id] = TextTools.acronym(se);
			} // if

			let subscribedCount = 0;
			for (const user of zenUsers) {
				if (user.customer_id === zc.id) {
					const notifyInfo = ValidationTools.getZenUserNotificationState(allUsers, zenOrgIDs, user);
					if (notifyInfo === 'Subscribed') subscribedCount++;
				} // if
			} // for

			this.notifyWarnings[zc.id] = subscribedCount === 0;
		} // for

		this.filterZens();
	}

	initForm() {
		this.zmTypeChoices = [];
		this.zmTypeChoices.push({ value: '', label: 'Unknown/Not Set' });
		for (const zenMasterType of AppConstants.zenMasterTypes)
			this.zmTypeChoices.push({ value: zenMasterType, label: AppConstants.zenMasterTypeLabels[zenMasterType] });

		this.assessmentChoices = [];
		for (const zenMasterAssessment of AppConstants.zenMasterAssessments)
			this.assessmentChoices.push({ value: zenMasterAssessment });

		this.enabledChoices = [];
		this.enabledChoices.push({ value: 1, label: 'Enabled/Online' });
		this.enabledChoices.push({ value: 0, label: 'Disabled/Offline' });

		this.recentTrafficChoices = [];
		this.recentTrafficChoices.push({ value: 1, label: 'Has Traffic in Last ' + AppConstants.assessmentThresholdDays + ' Days' });
		this.recentTrafficChoices.push({ value: 0, label: 'No Traffic in Last ' + AppConstants.assessmentThresholdDays + ' Days' });

		this.theForm = new UntypedFormGroup({
			enabledFilter: new UntypedFormControl(this.enabledFilter),
			assessmentFilters: new UntypedFormControl(this.assessmentFilters),
			zmTypeFilters: new UntypedFormControl(this.zmTypeFilters),
			recentTrafficFilters: new UntypedFormControl(this.recentTrafficFilters)
		});
	}

	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.enabledFilter = this.theForm.value.enabledFilter;
		this.assessmentFilters = this.theForm.value.assessmentFilters;
		this.zmTypeFilters = this.theForm.value.zmTypeFilters;
		this.recentTrafficFilters = this.theForm.value.recentTrafficFilters;

		localStorage.setItem('zenStaffReport.enabledFilter', JSON.stringify(this.enabledFilter));
		localStorage.setItem('zenStaffReport.assessmentFilters', JSON.stringify(this.assessmentFilters));
		localStorage.setItem('zenStaffReport.zmTypeFilters', JSON.stringify(this.zmTypeFilters));
		localStorage.setItem('zenStaffReport.recentTrafficFilters', JSON.stringify(this.recentTrafficFilters));

		this.filterZens();
	}

	async filterZens() {
		this.loading = true;

		this.zenCustomersToShow = [];
		for (const zc of this.zenCustomers) {
			let passedEnabledFilter = false;
			let passedTypeFilter = false;
			let passedAssessmentFilter = false;
			let passedRecentTrafficFilter = false;

			if (this.enabledFilter == null || this.enabledFilter === zc.is_enabled)
				passedEnabledFilter = true;

			if (!this.zmTypeFilters || this.zmTypeFilters.length === 0
				|| (this.zmTypeFilters.length !== 0 && zc.zcp_type && this.zmTypeFilters.includes(zc.zcp_type)))
				passedTypeFilter = true;

			if (this.zmTypeFilters.length !== 0 && this.zmTypeFilters.includes('') && (!zc.zcp_type || zc.zcp_type === ''))
				passedTypeFilter = true;

			if (!this.assessmentFilters || this.assessmentFilters.length === 0 || this.assessmentFilters.includes(zc.assessment))
				passedAssessmentFilter = true;

			const totalUsage = this.zenCustomersService.getUsageNoLoad(zc, 'zenmaster')
				+ this.zenCustomersService.getUsageNoLoad(zc, 'mediaconnect');

			if (!this.recentTrafficFilters || this.recentTrafficFilters.length === 0
				|| (this.recentTrafficFilters.includes(1) && totalUsage > 0) || (this.recentTrafficFilters.includes(0) && totalUsage === 0))
				passedRecentTrafficFilter = true;

			if (passedEnabledFilter && passedTypeFilter && passedAssessmentFilter && passedRecentTrafficFilter)
				this.zenCustomersToShow.push(zc);
		}

		this.people = [];
		this.reportData = {};

		if (this.staffMode === 'owner') {
			// 1st pass - get unique account owners (where the is one) and work out ordering
			let noOrgCount = 0;
			let noSFCount = 0;
			for (const zc of this.zenCustomersToShow) {
				const owner = this.getOwner(zc);
				if (owner === this.noOwnerNoOrg)
					noOrgCount++;
				else if (owner === this.noOwnerNoAcct)
					noSFCount++;
				else if (!this.people.includes(owner))
					this.people.push(owner);
			}

			this.people.sort();

			if (noOrgCount !== 0) this.people.push(this.noOwnerNoOrg);
			if (noSFCount !== 0) this.people.push(this.noOwnerNoAcct);

			// now collect up all the sites...
			for (const person of this.people) {
				const lists = {};
				for (const classification of this.classifications)
					lists[classification] = [];

				for (const zc of this.zenCustomersToShow) {
					const classification = this.getClassification(zc);
					if (!lists[classification]) lists[classification] = [];

					if (person === this.getOwner(zc))
						lists[classification].push(zc);
				}
				this.reportData[person] = lists;
			}
		} else {
			let noRepCount = 0;
			let noOrgCount = 0;

			for (const zc of this.zenCustomersToShow) {
				const se = this.getSE(zc);
				if (se === this.noOwnerNoOrg)
					noOrgCount++;
				else if (se === this.noOwnerNoAcct)
					noRepCount++;
				else if (!this.people.includes(se))
					this.people.push(se);
			}
			this.people.sort();

			if (noOrgCount !== 0) this.people.push(this.noRepNoOrg);
			if (noRepCount !== 0) this.people.push(this.noRepNotSet);

			// now collect up all the sites...
			for (const person of this.people) {
				const lists = {};
				for (const classification of this.classifications)
					lists[classification] = [];

				for (const zc of this.zenCustomersToShow) {
					const classification = this.getClassification(zc);
					if (!lists[classification]) lists[classification] = [];

					if (person === this.getSE(zc))
						lists[classification].push(zc);
				}
				this.reportData[person] = lists;
			}
		}

		this.loading = false;
	}

	getOwner(zenCustomer: Models.ZenMasterCustomer) {
		let owner = '';
		if (zenCustomer['' + 'organization']) {
			if (zenCustomer['' + 'organization'].salesforce_account_owner
				&& zenCustomer['' + 'organization'].salesforce_account_owner !== '')
				owner = zenCustomer['' + 'organization'].salesforce_account_owner;
			else
				owner = this.noOwnerNoAcct;

		} else {
			owner = this.noOwnerNoOrg;
		}
		return owner;
	}

	getSE(zenCustomer: Models.ZenMasterCustomer) {
		let se = '';
		if (zenCustomer['' + 'organization']) {
			if (zenCustomer['' + 'organization'].salesforce_se
				&& zenCustomer['' + 'organization'].salesforce_se !== '')
				se = zenCustomer['' + 'organization'].salesforce_se;
			else
				se = this.noRepNotSet;

		} else {
			se = this.noOwnerNoOrg;
		}
		return se;
	}

	getClassification(zenCustomer: Models.ZenMasterCustomer) {
		let classification = '';
		if (zenCustomer.zcp_type && zenCustomer.zcp_type !== '') {
			classification = zenCustomer.zcp_type + '-';
		} else {
			classification = 'unknown-';
		}

		if (zenCustomer.assessment === 'Disabled')
			classification = 'disabled';
		else if (zenCustomer.assessment === 'Active')
			classification += 'active';
		else
			classification += 'inactive';

		return classification;
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	scrollToSpot(id: string) {
		const el: HTMLElement | null = document.getElementById(id);
		if (el) {
			setTimeout(() =>
				el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 0);
		}
	}

	getTotalUsage(zc: Models.ZenMasterCustomer) {
		const mxUsage = this.zenCustomersService.getUsageNoLoad(zc, 'mediaconnect');
		const zmUsage = this.zenCustomersService.getUsageNoLoad(zc, 'zenmaster');
		return TextTools.formattedMB(mxUsage + zmUsage);
	}

	getUsage(zc: Models.ZenMasterCustomer, type: string) {
		// const mxUsage = this.zenCustomersService.getUsageNoLoad(zc, type);
		// const zmUsage = this.zenCustomersService.getUsageNoLoad(zc, 'zenmaster');
		return this.zenCustomersService.getUsageNoLoad(zc, type);
	}
}

