import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import PopOverTools from 'appshared/popover-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { LicensingAdminService } from '../licensing-admin.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UsersService } from '../../users/users.service';
import { UserGroupsService } from '../../users/user-groups.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { OrganizationGroupsService } from '../../organizations/organization-groups.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';

@Component({
	selector: 'app-key-template',
	templateUrl: './key-template.component.html',
	styleUrls: ['./key-template.component.scss']
})

export class KeyTemplateComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	popOverTools = PopOverTools;
	textTools = TextTools;
	miscTools = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;

	loading = true;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	template: Models.KeyTemplate;
	private userSubscription: Subscription;

	canEdit = false;
	canDelete = false;
	adminLogs: Models.AdminLog[] = [];
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	product: Models.LicenseProduct = null;
	allProperties: Models.LicenseProperty[] = [];

	verfiedProductProperties: Models.LicenseProductProperty[] = [];

	booleanPropsCount: number = 0;
	numberPropsCount: number = 0;
	otherPropsCount: number = 0;

	users: Models.User[] = [];
	userGroups: Models.UserGroup[] = [];

	limitOrgs: Models.Organization[] = [];
	limitOrgGroups: Models.OrganizationGroup[] = [];
	excludeOrgs: Models.Organization[] = [];
	excludeOrgGroups: Models.OrganizationGroup[] = [];

	deleteForm: UntypedFormGroup;
	showDeleteMsg = false;

	nonCheckAllFeatures: string[] = [];
	nonAllUnlimitedLimits: string[] = [];

	defaultCommercialTypeToShow: string = '';

	userTemplateIDs: number[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private usersService: UsersService,
		private userGroupsService: UserGroupsService,
		private organizationsService: OrganizationsService,
		private organizationGroupsService: OrganizationGroupsService,
		private licensingAdminService: LicensingAdminService,
		private uiAlertsService: UiAlertsService,
		private adminLogsService: AdminLogsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.canEdit = (authUser && ValidationTools.checkAccess(authUser, 'manage-key-templates'));
				this.loadData();
			});
		});
	}

	ngOnInit(): void {
		// changes

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;


		this.template = await this.licensingAdminService.getTemplate(this.id, false);
		if (!this.template || this.template == null || this.template.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
		}

		this.canDelete = this.canEdit && this.template.num_keys === 0;

		this.userTemplateIDs = await this.licensingAdminService.getUserTemplateIDs();

		this.product = await this.licensingAdminService.getProductByName(this.template.product);
		if (!this.product || this.product == null || this.product.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if
		// load all properties
		this.allProperties = await this.licensingAdminService.getProperties();
		const productProps: Models.LicenseProductProperty[] = await this.licensingAdminService.getProductProperties(false, this.product.id);

		this.booleanPropsCount = 0;
		this.numberPropsCount = 0;
		this.otherPropsCount = 0;

		this.verfiedProductProperties = [];

		this.nonCheckAllFeatures = [];
		this.nonAllUnlimitedLimits = [];

		for (const pp of this.template.settings.productProperties) {
			const idx1 = MiscTools.findIndexGenericDouble(productProps, 'product_id', pp.product_id, 'property_id', pp.property_id);
			if (idx1 === -1) {
				console.log('cannot find product:property ' + pp.product_id + ':' + pp.property_id);
				pp.product = null;
				pp.property = null;
			} else {
				if (!pp.product) pp.product = this.product;
				if (!pp.property) {
					const idx = MiscTools.findIndex(this.allProperties, pp.property_id);
					if (idx !== -1)
						pp.property = this.allProperties[idx];
					else
						console.log('cannot find property ' + pp.property_id);
				} // if
			} // if

			if (pp.property) {
				if (this.template.settings.nonCheckAllFeatures && this.template.settings.nonCheckAllFeatures.includes(pp.property_id))
					this.nonCheckAllFeatures.push(pp.property.label);

				if (this.template.settings.nonAllUnlimitedLimits && this.template.settings.nonAllUnlimitedLimits.includes(pp.property_id))
					this.nonAllUnlimitedLimits.push(pp.property.label);

				if (pp.property.ptype === 'boolean')
					this.booleanPropsCount++;
				else if (pp.property.ptype === 'number')
					this.numberPropsCount++;
				else if (pp.property.ptype === 'other')
					this.otherPropsCount++;

				this.verfiedProductProperties.push(pp);
			} // if
		}

		this.verfiedProductProperties.sort((a, b) => (a.property.sort_order > b.property.sort_order
			|| (a.property.sort_order === b.property.sort_order && a.property.label > b.property.label)) ? 1 : -1);

		this.addedByUser = null;
		if (this.template.added_by && this.template.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.template.added_by);

		this.editedByUser = null;
		if (this.template.edited_by && this.template.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.template.edited_by);

		this.users = [];
		if (this.template.user_ids) {
			for (const uid of this.template.user_ids) {
				const u = this.usersService.getOne(uid);
				if (u) {
					this.users.push(u);
				}
			}
			this.users.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.userGroups = [];
		if (this.template.user_group_ids) {
			for (const gid of this.template.user_group_ids) {
				const g = this.userGroupsService.getOne(gid);
				if (g) {
					this.userGroups.push(g);
				}
			}
			this.userGroups.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.limitOrgs = [];
		if (this.template.settings.limitOrgIDs) {
			for (const oid of this.template.settings.limitOrgIDs) {
				const o = this.organizationsService.getOne(oid);
				if (o) {
					this.limitOrgs.push(o);
				}
			}
			this.limitOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.limitOrgGroups = [];
		if (this.template.settings.limitOrgGroupIDs) {
			for (const gid of this.template.settings.limitOrgGroupIDs) {
				const g = this.organizationGroupsService.getOne(gid);
				if (g) this.limitOrgGroups.push(g);
			}
			this.limitOrgGroups.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.excludeOrgs = [];
		if (this.template.settings.excludeOrgIDs) {
			for (const oid of this.template.settings.excludeOrgIDs) {
				const o = this.organizationsService.getOne(oid);
				if (o) {
					this.excludeOrgs.push(o);
				}
			}
			this.excludeOrgs.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.excludeOrgGroups = [];
		if (this.template.settings.excludeOrgGroupIDs) {
			for (const gid of this.template.settings.excludeOrgGroupIDs) {
				const g = this.organizationGroupsService.getOne(gid);
				if (g) this.excludeOrgGroups.push(g);
			}
			this.excludeOrgGroups.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.defaultCommercialTypeToShow = '';
		if (this.template.settings.defaultCommercialType && this.template.settings.defaultCommercialType !== '') {
			const idx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', this.template.settings.defaultCommercialType);
			if (idx !== -1)
				this.defaultCommercialTypeToShow = (idx + 1) + '. ' + AppConstants.keyCommercialTypes[idx].short_label;
		}

		this.adminLogs = await this.adminLogsService.getLogs(['key-template'], this.id);


		this.deleteForm = new UntypedFormGroup({
			confirmation: new UntypedFormControl(null, [Validators.required])
		});

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-key-template-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

	}

	async delete() {
		this.loading = true;

		let confirmation = '';
		if (this.deleteForm.value.confirmation) confirmation = this.deleteForm.value.confirmation;

		if (confirmation.toLowerCase() !== 'delete') {
			this.showDeleteMsg = true;
			this.loading = false;
			return;
		} // if

		if (document.getElementById("closeDeleteModalButton"))
			document.getElementById("closeDeleteModalButton").click();

		const result = await this.licensingAdminService.deleteTemplate(this.template.id);
		if (result) {
			this.uiAlertsService.addMsg('The key template (' + this.template.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.licensingadmin, 'templates']);
		} else {
			return false;
		}
	}

	niceDefault(productProperty: Models.LicenseProductProperty): string {
		if (productProperty.property.ptype === 'boolean')
			return this.niceBoolean(productProperty.default_value_num);
		else if (productProperty.property.ptype === 'number' && productProperty.default_value_text !== 'unlimited')
			return productProperty.default_value_num + '';
		else if (productProperty.default_value_text && productProperty.default_value_text !== '')
			return productProperty.default_value_text;
		else
			return '';
	}

	niceBoolean(value: number): string {
		if (value === 0)
			return 'No';
		else if (value === 1)
			return 'Yes';
		else
			return '???-' + value;
	}

	niceBoolean2(value: boolean): string {
		if (value)
			return 'Yes';
		else
			return 'No';
	}

	niceShowUser(value: number): string {
		if (value >= 0 && value < AppConstants.keyPropShowUserValues.length)
			return AppConstants.keyPropShowUserValues[value];
		else
			return '???-' + value;
	}

	stringify(obj: any) {
		return JSON.stringify(obj, null, 4);
	}

	chopString(str: string, length: number = 25) {
		return TextTools.chopString(str, length);
	}

	getProductPropertiesByType(type: string) {
		const sublist: Models.LicenseProductProperty[] = [];
		if (this.template && this.template.settings)
			for (const pp of this.verfiedProductProperties)
				if (pp.property && pp.property.ptype === type) sublist.push(pp);
		return sublist;
	}

	runKeySearchForTemplate() {
		const searchSettings: Models.KeySearchSettings = new Models.KeySearchSettings();
		searchSettings.keyTemplateFilter = [this.id];
		localStorage.setItem('licensingSearch.searchOptions', JSON.stringify(searchSettings));
		sessionStorage.setItem('licensingSearch.autoRun', 'yes');
		this.router.navigate(['/' + AppConstants.urls.licensing]);
	}
}
