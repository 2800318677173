import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { LoginLogsService } from './login-logs.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../users/users.service';
import { AdminLogsService } from '../admin-logs/admin-logs.service';

import { LoginsTableComponent } from 'client/app/components/shared/logins-table/logins-table.component';

@Component({
	selector: 'app-login-logs',
	templateUrl: './login-logs.component.html',
	styleUrls: ['./login-logs.component.scss']
})
export class LoginLogsComponent implements OnInit, OnDestroy {
	appConstants = AppConstants;
	textTools = TextTools;

	@ViewChild('loginsTable1') loginsTable1: LoginsTableComponent = null;

	logs: Models.LoginHistory[];
	loading = true;
	refreshing = false;

	// other stuff...
	maxMessage = '';
	showFilters = true;
	theForm: UntypedFormGroup;
	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;

	private userSubscription: Subscription;
	role = '';
	users: Models.User[] = [];

	constructor(
		private router: Router,
		private loginLogsService: LoginLogsService,
		private adminLogsService: AdminLogsService,
		private usersService: UsersService,
		private authService: AuthService) { }

	ngOnInit(): void {
		const notBefore = JSON.parse(localStorage.getItem('loginlogs.notBefore'));
		if (notBefore) this.notBefore = notBefore;

		const notAfter = JSON.parse(localStorage.getItem('loginlogs.notAfter'));
		if (notAfter) this.notAfter = notAfter;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser)
				this.role = authUser.role;
		});

		this.loadUsers();
		this.initForm();
		this.loadLogs();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});
	}

	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		localStorage.setItem('loginlogs.notBefore', JSON.stringify(this.notBefore));
		localStorage.setItem('loginlogs.notAfter', JSON.stringify(this.notAfter));

		this.loadLogs();
	}

	async loadLogs() {
		this.loading = true;

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		// this.logs = [];
		this.logs = await this.loginLogsService.getLogs(startDate, endDate);

		const failedLogins: Models.AdminLog[] = await this.adminLogsService.getLogs(['authentication'], 0, ['failed login'], startDate, endDate);
		for (const logEntry of failedLogins) {
			let location = '';
			const idx = logEntry.information.indexOf(' from ');
			if (idx !== -1) location = logEntry.information.substring(idx + 6).trim();

			let userInfo = logEntry.user_info;
			if (!userInfo || userInfo === '') userInfo = logEntry.obj_name;

			let userID = logEntry.obj_id * -1;
			if (userID === 0) {
				const idx2 = MiscTools.findIndexGeneric(this.users, 'email', TextTools.parseUserInfo(userInfo, 'email'));
				if (idx2 !== -1) userID = this.users[idx2].id * -1;
			}

			this.logs.push(new Models.LoginHistory(
				new Date(logEntry.added_on),
				0,
				userID,
				userInfo,
				location,
				'',
				0,
				''));
		}

		this.logs.sort((a, b) => ((new Date(a.login_on)).getTime() < (new Date(b.login_on)).getTime()) ? 1 : -1);

		if (this.logs.length > AppConstants.maxLogsToFetch)
			this.logs = this.logs.slice(0, AppConstants.maxLogsToFetch);

		if (this.logs && this.logs.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum logs (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of logs retrieved.';
		else
			this.maxMessage = '';

		await MiscTools.delay(100);
		if (this.loginsTable1)
			this.loginsTable1.updateContent(this.logs, 'cp-login-logs', { showUserInfo: true, linkUser: true });

		this.loading = false;
	}

	async loadUsers() {
		this.users = this.usersService.getAll();
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
	}
}
