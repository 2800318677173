import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from 'client/app/modules/shared/shared.module';
import { FontAwesome } from 'client/app/modules/font-awesome/font-awesome.module';

import { OrganizationsRoutingModule } from './organizations-routing.module';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { OrganizationFormComponent } from './organization-form/organization-form.component';
import { ImportSalesForceUsersComponent } from './import-sales-force-users/import-sales-force-users.component';
import { LinkKeysFormComponent } from './link-keys-form/link-keys-form.component';
import { OrganizationBillingCodeFormComponent } from './organization-billing-code-form/organization-billing-code-form.component';
import { OrganizationBillingCodesFormComponent } from './organization-billing-codes-form/organization-billing-codes-form.component';
import { ImportZenMasterUsersComponent } from './import-zen-master-users/import-zen-master-users.component';
import { OrganizationNotificationFormComponent } from './organization-notification-form/organization-notification-form.component';

import { OrganizationGroupComponent } from './organization-group/organization-group.component';
import { OrganizationGroupListComponent } from './organization-group-list/organization-group-list.component';
import { OrganizationGroupFormComponent } from './organization-group-form/organization-group-form.component';
import { OrganizationGroupNotificationFormComponent } from './organization-group-notification-form/organization-group-notification-form.component';

@NgModule({
	declarations: [OrganizationComponent,
		OrganizationListComponent,
		OrganizationFormComponent,
		ImportSalesForceUsersComponent,
		LinkKeysFormComponent,
		OrganizationBillingCodeFormComponent,
		OrganizationBillingCodesFormComponent,
		ImportZenMasterUsersComponent,
		OrganizationNotificationFormComponent,
		OrganizationGroupComponent,
		OrganizationGroupListComponent,
		OrganizationGroupFormComponent,
		OrganizationGroupNotificationFormComponent],
	imports: [
		OrganizationsRoutingModule,
		CommonModule,
		ReactiveFormsModule,
		NgbModule,
		NgSelectModule,
		SharedModule,
		FontAwesome,
		ClipboardModule
	]
})
export class OrganizationsModule { }
