<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="appConstants.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Organizations</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ organization.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!organization">
				<button *ngIf="organization.is_favorite && organization.is_favorite === 1" type="button" class="btn btn-outline-warning"
					(click)="toggleFavorite()" placement="start" ngbTooltip="Remove from Favorites">
					<fa-icon [icon]="starIcon" size="sm" [spin]="spinFavorite"></fa-icon>
					<span class="cp-action-button-text">-Fav</span>
				</button>

				<button *ngIf="!organization.is_favorite || organization.is_favorite === 0" type="button" class="btn btn-outline-success"
					(click)="toggleFavorite()" placement="start" ngbTooltip="Add to Favorites">
					<fa-icon [icon]="starIcon" size="sm" [spin]="spinFavorite"></fa-icon>
					<span class="cp-action-button-text">+Fav</span>
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canSendNotifications" type="button" class="btn btn-outline-primary" [routerLink]="['notify']"
					ngbTooltip="Notify" placement="bottom-end">
					<fa-icon [icon]="appConstants.sectionIcons.mynotifications" size="sm"></fa-icon>
				</button>

				<button *ngIf="canEdit && organization.is_enabled === 1" type="button" class="btn btn-outline-warning" (click)="disable()"
					ngbTooltip="Disabling an Organization will prevent its users from accessing content granted to it" placement="bottom">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canEnable && organization.is_enabled === 0" type="button" class="btn btn-outline-success" (click)="enable()"
					ngbTooltip="Enable" placement="start">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button *ngIf="canDelete && deleteForm" type="button" class="btn btn-outline-danger" data-bs-toggle="modal"
					data-bs-target="#deleteModal" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="showUserAdd || users.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-users-tab"
				aria-controls="nav-users" data-bs-target="#nav-users"
				ngbTooltip="Users linked this Organization that are enabled and can login">
				<fa-icon [icon]="appConstants.sectionIcons.users" size="sm"></fa-icon>
				Users
				<span *ngIf="!loading || users.length > 0" class="cp-tab-badge">
					{{ users.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="users2.length" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-users2-tab"
				aria-controls="nav-users2" data-bs-target="#nav-users2"
				ngbTooltip="Users linked this Organization that are disabled and cannot login">
				<fa-icon [icon]="appConstants.sectionIcons.users" size="sm"></fa-icon>
				Users<sup class="me-1">2</sup>
				<span *ngIf="!loading || users2.length > 0" class="cp-tab-badge">
					{{ users2.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showBillingCodeTab" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-billing-codes-tab" aria-controls="nav-billing-codes" data-bs-target="#nav-billing-codes">
				<fa-icon [icon]="['fas', 'sack-dollar']" size="sm"></fa-icon>
				B-Codes
				<span *ngIf="!loading || billingCodes.length > 0" class="cp-tab-badge">
					{{ billingCodes.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showKeysTab || keys.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-keys-tab"
				aria-controls="nav-keys" data-bs-target="#nav-keys"
				ngbTooltip="Keys linked this Organization that are enabled and haven't expired">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" size="sm"></fa-icon>
				Keys
				<span *ngIf="!loading || keys.length > 0" class="cp-tab-badge">
					{{ keys.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="keys2.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-keys-obsolete-tab" aria-controls="nav-keys-obsolete" data-bs-target="#nav-keys-obsolete"
				ngbTooltip="Keys linked this Organization that are disabled and/or have expired">
				<fa-icon [icon]="appConstants.sectionIcons.licensing" size="sm"></fa-icon>
				Keys<sup class="me-1">2</sup>
				<span *ngIf="!loading || keys.length > 0" class="cp-tab-badge">
					{{ keys2.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="failedPenTests && failedPenTests.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-pen-test-tab"
				aria-controls="nav-pen-test" data-bs-target="#nav-pen-test">
				<fa-icon [icon]="['fas', 'lock-open']" size="sm"></fa-icon>
				Failed Pen Tests
				<span class="cp-tab-badge">
					{{ failedPenTests.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-pp-tab"
				aria-controls="nav-pp" data-bs-target="#nav-pp">
				<fa-icon [icon]="appConstants.sectionIcons.products" size="sm"></fa-icon>
				Products
				<span class="cp-tab-badge">
					{{ organization.product_platforms.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showDirectTab" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-direct-tab"
				aria-controls="nav-direct" data-bs-target="#nav-direct">
				Direct Access
				<span class="cp-tab-badge">
					{{ (builds.length + documents.length) | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-builds-tab" aria-controls="nav-downloads-builds" data-bs-target="#nav-downloads-builds"
				ngbTooltip="Software Builds Available to Users in this Organization">
				<fa-icon [icon]="appConstants.sectionIcons.mybuilds" size="sm"></fa-icon>
				SW
				<span *ngIf="!loading || buildDownloads.length > 0" class="cp-tab-badge">
					{{ buildDownloads.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-documents-tab" aria-controls="nav-downloads-documents" data-bs-target="#nav-downloads-documents"
				ngbTooltip="Documentation Available to Users in this Organization">
				<fa-icon [icon]="appConstants.sectionIcons.mydocuments" size="sm"></fa-icon>
				Docs
				<span *ngIf="!loading || documentDownloads.length > 0" class="cp-tab-badge">
					{{ documentDownloads.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-download-log-tab" aria-controls="nav-download-log" data-bs-target="#nav-download-log">
				<fa-icon [icon]="appConstants.sectionIcons.downloadlogs" size="sm"></fa-icon>
				<!-- Downloads -->
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-journal-tab"
				aria-controls="nav-journal" data-bs-target="#nav-journal">
				<fa-icon [icon]="appConstants.sectionIcons.journals" size="sm"></fa-icon>
				<!-- Journal -->
				<span *ngIf="journals && journals.length > 0" class="cp-tab-badge ms-1">
					{{ journals.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="appConstants.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div *ngIf="!!organization && onboardingStatus && onboardingStatus.length !== 0" class="cp-warning-message ms-3 mt-2 mb-0">
		<div *ngFor="let item of onboardingStatus">
			<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
			{{ item }}
		</div>
	</div>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!organization" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span *ngIf="organization.is_enabled === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled -
						Downloads available
					</span>
					<span *ngIf="organization.is_enabled === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Disabled - Downloads
						not available
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ organization.name }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Type:</span>
					<span class="cp-property-value">
						{{ appConstants.organizationTypeLabels[organization.otype] }}
					</span>
				</div>

				<div class="cp-property-block" *ngIf="!!organization.information && organization.information !== ''">
					<span class="cp-property-label">Information:</span>
					<span class="cp-property-value cp-pre-text">{{ organization.information }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!organization.email_domains && organization.email_domains !== ''">
					<span class="cp-property-label">
						E-Mail Domain(s):
						<fa-icon [icon]="['fas', 'question-circle']"
							ngbTooltip="If set, the SE/tech-rep for this organization will receive an e-mail alert when a new user with an e-mail address from that domain registers."
							placement="bottom-start"> </fa-icon>
					</span>
					<span class="cp-property-value">{{ organization.email_domains }}</span>
				</div>

				<div *ngIf="parentOrganization != null" class="cp-property-block">
					<span class="cp-property-label">Parent Organization:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + appConstants.urls.organizations, parentOrganization.id]">
							{{ parentOrganization.name }}</a>
					</span>
				</div>

				<div *ngIf="childOrganizations && childOrganizations.length > 0" class="cp-property-block">
					<span class="cp-property-label">Sub-Organizations:</span>
					<span class="cp-property-value">

						<ul class="mb-0">
							<li *ngFor="let suborg of childOrganizations" class="cp-property-value">
								<a [routerLink]="['/' + appConstants.urls.organizations, suborg.id]">
									{{ suborg.name }}</a>
							</li>
						</ul>
					</span>
				</div>

				<div *ngIf="users.length > 0" class="cp-property-block">
					<span class="cp-property-label"># Users:</span>
					<span class="cp-property-value">
						{{ organization.num_users | number }}

						<span *ngIf="users2.length > 0">
							({{ users.length | number }} enabled, {{ users2.length | number }} disabled)
						</span>

						<fa-icon *ngIf="emails && emails.length > 0" [icon]="['fas', 'copy']" ngxClipboard
							(click)="copyToClipboardAlert('')" [cbContent]="emails.join(';')"
							ngbTooltip="Copy E-Mail Addresses of Enabled Users to Clipboard"></fa-icon>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="!!organization.salesforce_account_id && organization.salesforce_account_id !== ''">
					<span class="cp-property-label">Salesforce Account:</span>
					<span class="cp-property-value"><a *ngIf="salesForceUrl !== ''" target="_blank"
							href="{{ salesForceUrl }}/lightning/r/Account/{{organization.salesforce_account_id}}/view">
							{{ organization.salesforce_account_id }}
						</a>
						<!-- <button *ngIf="salesforceAccountInfoBlock === ''" type="button"
							class="btn btn-outline-info btn-sm m-1" (click)="getSalesforceInfoBlocks()"
							ngbTooltip="Get Info From Salesforce">
							<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
						</button> -->

						<span *ngIf="salesforceAccountInfoBlock === ''" class="text-success cp-pointer" (click)="getSalesforceInfoBlocks()"
							ngbTooltip="Get Info From Salesforce">
							<fa-icon [icon]="['fab', 'salesforce']" [spin]="sfFetching"></fa-icon>
						</span>

						<div *ngIf="salesforceAccountInfoBlock !== ''" class="border p-2 ms-4 me-4 mb-2 cp-pre-text cp-general-width-limit">
							<span class="float-end cp-pointer">
								&nbsp; <fa-icon (click)="salesforceAccountInfoBlock = ''" [icon]="['fas', 'times-circle']"></fa-icon>
							</span>
							{{ salesforceAccountInfoBlock }}
						</div>
					</span>
				</div>

				<div class="cp-property-block"
					*ngIf="organization.salesforce_account_owner && organization.salesforce_account_owner !== ''">
					<span class="cp-property-label">Account Owner:</span>
					<span class="cp-property-value">
						<span *ngIf="!sfOwnerUser">
							{{ organization.salesforce_account_owner }}
						</span>
						<span *ngIf="sfOwnerUser">
							<a [routerLink]="['/' + appConstants.urls.users, sfOwnerUser.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ organization.salesforce_account_owner }}
							</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ sfOwnerUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(sfOwnerUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="organization.salesforce_se && organization.salesforce_se !== ''">
					<span class="cp-property-label">SE/Tech-Rep:</span>
					<span class="cp-property-value">
						<span *ngIf="!sfSEUser">
							{{ organization.salesforce_se }}
						</span>
						<span *ngIf="sfSEUser">
							<a [routerLink]="['/' + appConstants.urls.users, sfSEUser.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ organization.salesforce_se }}
							</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ sfSEUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(sfSEUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="organization.last_download">
					<span class="cp-property-label">Last Download:</span>
					<span class="cp-property-value">
						{{ organization.last_download | date: appConstants.pageDateTimeFmt }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ organization.added_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ addedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(addedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="organization.added_on !== organization.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ organization.edited_on | date: appConstants.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + appConstants.urls.users, editedByUser.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ editedByUser.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(editedByUser).join('\n')}}</div>
							</ng-template>
						</span>
					</span>
				</div>


				<div class="cp-property-block" *ngIf="flagsToShow.length !== 0">
					<span class="cp-property-label">Special Flags:</span>
					<ul class="mb-0">
						<li *ngFor="let flagToShow of flagsToShow" class="cp-property-value">
							{{ flagToShow }}
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="groups.length !== 0">
					<span class="cp-property-label">Groups:</span>
					<ul class="mb-0">
						<li *ngFor="let group of groups" class="cp-property-value">
							<fa-icon *ngIf="group.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.organizationgroups, group.id]">
								{{ group.name }}</a>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!showDirectTab && builds.length !== 0">
					<span class="cp-property-label">Direct Access Builds:</span>
					<ul class="mb-0">
						<li *ngFor="let build of builds" class="cp-property-value">
							<fa-icon *ngIf="build.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="build.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="build.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Build is not enabled"></fa-icon>
							<fa-icon *ngIf="build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info me-1"
								ngbTooltip="Archived - no longer downloadable without direct access">
							</fa-icon>
							<a [routerLink]="['/' + appConstants.urls.builds, build.id]" [ngbPopover]="buildPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ build.prod_name }} -
								{{ build.version }}
								<span *ngIf="build.label && build.label !== ''">({{ build.label }})</span></a>
							{{ buildExtra(build.id)}}

							<ng-template #buildPopupContent>
								<div class="cp-popover-banner">{{ build.version }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getBuildPopoverLines(build).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!showDirectTab && documents.length !== 0">
					<span class="cp-property-label">Direct Access Documents:</span>
					<ul class="mb-0">
						<li *ngFor="let document of documents" class="cp-property-value">
							<fa-icon *ngIf="document.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="document.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="document.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger me-1"
								ngbTooltip="Document is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.documents, document.id]" [ngbPopover]="documentPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ document.label }}</a>
							{{ documentExtra(document.id)}}
							<ng-template #documentPopupContent>
								<div class="cp-popover-banner">{{ document.label }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getDocumentPopoverLines(document).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="partnerships && partnerships.length > 0">
					<span class="cp-property-label">Partnerships:</span>
					<ul class="mb-0">
						<li *ngFor="let item of partnerships" class="cp-property-value">
							<fa-icon *ngIf="item.is_listed === 0" [icon]="['fas', 'ban']" class="ms-1 text-danger"
								ngbTooltip="Not eabled. Not listed in Partners Directory"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.partneradmin, item.id]" [ngbPopover]="partnerPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ item.name }}</a>
							<ng-template #partnerPopupContent>
								<div class="cp-popover-banner">{{ item.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getPartnershipPopoverLines(item, [organization]).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="zenmasters && zenmasters.length > 0">
					<span class="cp-property-label">ZEN Master Sites:</span>
					<ul class="mb-0">
						<li *ngFor="let item of zenmasters" class="cp-property-value">
							<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Site is not enabled/running"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.zencustomers, item.id]" [ngbPopover]="zmPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ item.name }} ({{ item.dns_prefix }})
							</a>
							<ng-template #zmPopupContent>
								<div class="cp-popover-banner">{{ item.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getZenMasterPopoverLines(item, [organization]).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<button *ngIf="authUser.role === 'admin'" type="button" class="btn btn-outline-secondary btn-sm mb-2 ms-1"
					(click)="openAccountReview()">
					Account Review
				</button>
			</div>
		</div>

		<div class="tab-pane" id="nav-pp" role="tabpanel" aria-labelledby="nav-pp-tab">
			<div *ngIf="!!organization && organization.product_platforms.length === 0" class="cp-light-message">
				No Products/Platforms selected for {{ organization.name }}.
			</div>

			<div *ngIf="!!organization  && organization.product_platforms.length !== 0" class="cp-property-list">
				<div *ngFor="let productType of productTypesToShow">
					<strong>{{ appConstants.productTypeLabels[productType] }} Products</strong>
					<ul class="mb-0">
						<li *ngFor="let product of subListOfUsedProducts(productType)">
							<span class="fw-bold">{{ product.name }}</span><br />
							<span
								*ngFor="let pl of miscTools.sortPlatforms(allPlatforms, getPlatformIdsForProduct(product.id, organization.product_platforms))">
								<fa-icon [icon]="miscTools.getPlatformIcon(pl.name)"></fa-icon>
								{{ pl.name }}&nbsp;
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-direct" role="tabpanel" aria-labelledby="nav-direct-tab">

			<div *ngIf="organization" class="cp-light-message">
				The following Builds and/or Documents have been directly assigned to {{ organization.name }}.
			</div>

			<div *ngIf="organization" class="cp-property-list">
				<div class="cp-property-block" *ngIf="builds.length !== 0">
					<span class="cp-property-label">Direct Access Builds:</span>
					<ul class="mb-0">
						<li *ngFor="let build of builds" class="cp-property-value">
							<fa-icon *ngIf="build.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="build.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="build.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger me-1"
								ngbTooltip="Build is not enabled"></fa-icon>
							<fa-icon *ngIf="build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info me-1"
								ngbTooltip="Archived - no longer downloadable without direct access">
							</fa-icon>

							<a [routerLink]="['/' + appConstants.urls.builds, build.id]" [ngbPopover]="buildPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ build.prod_name }} -
								{{ build.version }}
								<span *ngIf="build.label && build.label !== ''">({{ build.label }})</span></a>
							{{ buildExtra(build.id)}}
							<ng-template #buildPopupContent>
								<div class="cp-popover-banner">{{ build.version }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getBuildPopoverLines(build).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<div *ngIf="documents.length !== 0">
					<span class="cp-property-label">Direct Access Documents:</span>
					<ul class="mb-0">
						<li *ngFor="let document of documents" class="cp-property-value">
							<fa-icon *ngIf="document.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="document.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="document.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger me-1"
								ngbTooltip="Document is not enabled"></fa-icon>
							<a [routerLink]="['/' + appConstants.urls.documents, document.id]" [ngbPopover]="documentPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ document.label }}</a>
							{{ documentExtra(document.id)}}
							<ng-template #documentPopupContent>
								<div class="cp-popover-banner">{{ document.label }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getDocumentPopoverLines(document).join('\n')}}</div>
							</ng-template>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-downloads-builds" role="tabpanel" aria-labelledby="nav-downloads-builds-tab">
			<div *ngIf="!loading && (!buildDownloads || buildDownloads.length === 0)" class="cp-light-message">
				No software available for {{ organization.name }}.
				<span *ngIf="organization.is_enabled === 0">
					{{ organization.name }} is disabled.
				</span>
				<span *ngIf="organization.product_platforms.length === 0">
					{{ organization.name }} does not have access to any products & platforms.
				</span>
			</div>

			<app-downloadable-builds-table #dBuildsTable1></app-downloadable-builds-table>
		</div>

		<div class="tab-pane" id="nav-downloads-documents" role="tabpanel" aria-labelledby="nav-downloads-documents-tab">
			<div *ngIf="!loading && (!documentDownloads || documentDownloads.length === 0)" class="cp-light-message">
				No documentation available for {{ organization.name }}.
				<span *ngIf="organization.is_enabled === 0">
					{{ organization.name }} is disabled.
				</span>
				<span *ngIf="organization.product_platforms.length === 0">
					{{ organization.name }} does not have access to any products & platforms.
				</span>
			</div>

			<app-downloadable-documents-table #dDocsTable1></app-downloadable-documents-table>
		</div>

		<div class="tab-pane" id="nav-download-log" role="tabpanel" aria-labelledby="nav-download-log-tab">
			<div *ngIf="!loading && downloadLogs.length === 0" class="cp-light-message">
				No download logs for {{ organization.name }}.
			</div>

			<button *ngIf="!loading && downloadLogs.length !== 0" type="button" (click)="runDownloadsReport()"
				class="btn btn-sm btn-outline-secondary mb-1">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Generate Report
			</button>

			<app-downloads-table #downloadsTable1></app-downloads-table>
		</div>

		<div class="tab-pane" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">

			<div *ngIf="!loading && (!!organization && users.length === 0)" class="cp-light-message">
				No Users assigned to {{ organization.name }}.
			</div>

			<button *ngIf="showUserAdd && !!organization.salesforce_account_id &&	organization.salesforce_account_id !== ''" type="button"
				class="btn btn-outline-primary btn-sm mb-2 me-1" [routerLink]="['salesforce-contacts']">
				<fa-icon [icon]="['fab', 'salesforce']"></fa-icon> Import Contacts from Salesforce
			</button>

			<button *ngIf="showUserAdd && zenmasters &&	zenmasters.length > 0" type="button"
				class="btn btn-outline-primary btn-sm mb-2 me-1" [routerLink]="['zen-master-users']">
				<fa-icon [icon]="appConstants.sectionIcons.zencustomers"></fa-icon> Import Users from ZEN Master
			</button>

			<button *ngIf="showUserAdd" type="button" class="btn btn-outline-primary btn-sm mb-2"
				[routerLink]="['/' + appConstants.urls.users, 'new', id]">
				<fa-icon [icon]="['fas', 'user-plus']" size="sm"></fa-icon> New User
			</button>

			<app-users-table #usersTable1></app-users-table>
		</div>

		<div class="tab-pane" id="nav-users2" role="tabpanel" aria-labelledby="nav-users2-tab">

			<app-users-table #usersTable2></app-users-table>

		</div>

		<div *ngIf="showBillingCodeTab" class="tab-pane" id="nav-billing-codes" role="tabpanel" aria-labelledby="nav-billing-codes-tab">

			<button *ngIf="canManageBillingCodes" type="button" class="btn btn-outline-primary btn-sm me-1"
				[routerLink]="['billing-codes', 'new']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon> Add Billing Code(s)
			</button>

			<button *ngIf="!!organization && billingCodes.length !== 0" type="button" class="btn btn-outline-primary btn-sm me-1"
				(click)="runKeySearchForBillingCodes()">
				<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
				Find Keys Using These Billing Codes
			</button>

			<button *ngIf="!!organization && billingCodes.length !== 0" type="button" class="btn btn-outline-secondary btn-sm me-1"
				(click)="openBillingCodeReport()">
				<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
				Report
			</button>


			<table *ngIf="billingCodes && billingCodes.length > 0" id="billingCodesTable"
				class="table table-sm table-bordered cp-auto-width mt-2">
				<thead>
					<tr>
						<th>Name/Label</th>
						<th>Code</th>
						<th *ngIf="showBillingCodeAuth">Authorization</th>
						<th>Added</th>
						<th>Updated</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of billingCodes">
						<td>{{ item.label }}</td>
						<td class="cp-key">
							{{ item.billing_code }}
							<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert(item.billing_code)"
								[cbContent]="item.billing_code" ngbTooltip="Copy to Clipboard" class="ms-1"></fa-icon>
						</td>
						<td *ngIf="showBillingCodeAuth" class="cp-key">
							{{ item.auth_code }}
							<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert('')" [cbContent]="item.auth_code"
								ngbTooltip="Copy to Clipboard" class="ms-1"></fa-icon>
						</td>
						<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.added_on)">
							{{ item.added_on | date: appConstants.tableDateFmt }}
						</td>
						<td class="text-nowrap" [attr.data-order]="textTools.formatDateTimeUTC(item.edited_on)">
							{{ item.edited_on | date: appConstants.tableDateFmt }}
						</td>
						<td>
							<button *ngIf="canManageBillingCodes" type="button" class="btn btn-outline-primary btn-sm me-1"
								[routerLink]="['billing-codes', item.id, 'edit']">
								<fa-icon [icon]="['fas', 'pencil']" size="sm"></fa-icon>
								Edit
							</button>
							<button *ngIf="canManageBillingCodes" type="button" class="btn btn-outline-primary btn-sm"
								(click)="resetBillCodeAuthCode(item.id)">
								Reset
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="tab-pane" id="nav-keys" role="tabpanel" aria-labelledby="nav-keys-tab">
			<div *ngIf="!loading && keys.length === 0" class="cp-light-message">
				{{ organization.name }} does't have keys that aren't disabled and/or expired linked to it.
			</div>

			<div *ngIf="keys.length !== 0" class="cp-light-message">
				Users in an Organization do not (automatically) see keys linked to their Organization.<br />
				Keys must be registered for a specific User either by Staff or by the User.
			</div>

			<div *ngIf="keys2.length !== 0" class="cp-light-message">
				This organization has {{ keys2.length }} key(s) that are disabled and/or expired. They are listed under
				the
				Keys<sup>2</sup> tab.
			</div>

			<div class="row mb-2">

				<div class="col-9">
					<button *ngIf="canManageKeys && !!organization" type="button" class="btn btn-outline-primary btn-sm mb-2 ms-1"
						routerLink="link-keys" ngbTooltip="Link one or more legacy keys to this Organization" placement="bottom">
						<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
						Link Keys
					</button>

					<button *ngIf="canManageKeys" type="button" class="btn btn-outline-primary btn-sm mb-2 ms-1"
						[routerLink]="['/' + appConstants.urls.licensing, 'new']" [queryParams]="{orgID: id}" ngbTooltip="Create new key"
						placement="bottom">
						<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
						Add Key
					</button>

					<button *ngIf="!!organization && keys.length !== 0" type="button" class="btn btn-outline-primary btn-sm mb-2 ms-1"
						(click)="runKeySearchForOrganization()" ngbTooltip="Find Keys Using Licensing/Search" placement="bottom">
						<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
						Find Keys
					</button>

					<button *ngIf="!!organization && keys.length !== 0 && keys.length <= appConstants.maxAggregateKeys" type="button"
						class="btn btn-outline-primary btn-sm mb-2 ms-1" (click)="doAggregate()"
						ngbTooltip="View Aggregate Stats Across All Keys" placement="bottom">
						<fa-icon [icon]="['fas', 'binoculars']" size="sm"></fa-icon>
						Aggregate
					</button>

					<button *ngIf="!!organization && keys.length !== 0" type="button" class="btn btn-outline-secondary btn-sm mb-2 ms-1"
						(click)="openKeyReport('meter-data')"
						ngbTooltip="Generate a Report (Using Meter Usage Data) Across All Keys for this Organization" placement="bottom">
						<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
						Meter Report
					</button>

					<button *ngIf="!!organization && keys.length !== 0" type="button" class="btn btn-outline-secondary btn-sm mb-2 ms-1"
						(click)="openKeyReport('protocol-data')"
						ngbTooltip="Generate a Report (Using Protocol Usage Data) Across All Keys for this Organization" placement="bottom">
						<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
						Protocol Report
					</button>

					<button *ngIf="!!organization && keys.length !== 0" type="button" class="btn btn-outline-secondary btn-sm mb-2 ms-1"
						(click)="kickOffOrgMeterReport()" ngbTooltip="E-Mail Me a Summary of this Organization\'s Keys Based on Meter Usage"
						placement="bottom">
						<fa-icon [icon]="['fas', 'paper-plane']" size="sm"></fa-icon>
						Meter Summary
					</button>

					<button *ngIf="!!organization && keys.length !== 0" type="button" class="btn btn-outline-secondary btn-sm mb-2 ms-1"
						(click)="kickOffOrgProtocolReport()"
						ngbTooltip="E-Mail Me a Summary of this Organization\'s Keys Based on Protocol Usage" placement="bottom">
						<fa-icon [icon]="['fas', 'paper-plane']" size="sm"></fa-icon>
						Protocol Summary
					</button>
				</div>

				<div *ngIf="keys.length !== 0" class="col-3 text-end">
					<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()"
						ngbTooltip="Toggle Whether Full Warnings are Shown or Not">
						<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Warnings
					</button>
					<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()"
						ngbTooltip="Toggle Whether Full Warnings are Shown or Not">
						<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Warnings
					</button>
				</div>
			</div>

			<app-keys-table #keysTable1></app-keys-table>
		</div>

		<div class="tab-pane" id="nav-keys-obsolete" role="tabpanel" aria-labelledby="nav-keys-obsolete-tab">

			<div class="row mb-2">

				<div class="col-9">

				</div>

				<div *ngIf="keys.length !== 0" class="col-3 text-end">
					<button *ngIf="expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()"
						ngbTooltip="Toggle Whether Full Warnings are Shown or Not">
						<fa-icon [icon]="['fas', 'toggle-on']" size="sm"></fa-icon> Warnings
					</button>
					<button *ngIf="!expandWarnings" class="btn btn-outline-secondary btn-sm" type="button" (click)="toggleWarnings()"
						ngbTooltip="Toggle Whether Full Warnings are Shown or Not">
						<fa-icon [icon]="['fas', 'toggle-off']" size="sm"></fa-icon> Warnings
					</button>
				</div>
			</div>

			<app-keys-table #keysTable2></app-keys-table>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-pen-test" aria-labelledby="nav-pen-test-tab">

			<button type="button" class="btn btn-outline-secondary btn-sm mb-2 ms-1" (click)="runPenTestReport()">
				<fa-icon [icon]="['fas', 'file-excel']" size="sm"></fa-icon>
				Generate Pen Test Report {{ organization.name }}
			</button>

			<table *ngIf="failedPenTests && failedPenTests.length !== 0" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class="border-start">Key</th>
						<th class="border-start">Host ID</th>
						<th class="border-start">IP</th>
						<th class="border-start">User</th>
						<th class="border-start">First Check</th>
						<th class="border-start">Latest Check</th>
						<th class="border-start">Details</th>
						<th class="border-start"></th>
						<th class="border-start"></th>
						<th class="border-start"></th>
					</tr>
				</thead>

				<tbody>
					<tr *ngFor="let p of failedPenTests" class="cp-pointer">
						<td class="border-start text-nowrap">
							<a [routerLink]="['/' + appConstants.urls.licensing, 'activation', p.activation_id]">
								{{ getKey(p.activation_id) }}</a>
						</td>

						<td class="border-start text-nowrap">
							<a [routerLink]="['/' + appConstants.urls.licensing, 'hostid', p.hostid, this.id]">
								{{ p.hostid }}</a>
						</td>
						<td class="border-start text-nowrap">
							<a [routerLink]="['/' + appConstants.urls.licensing, 'ip', p.ip]">
								{{ p.ip }}
							</a>
						</td>
						<td class="border-start text-nowrap">
							{{ p.username }}
						</td>
						<td class="border-start text-nowrap">
							{{ p.added_on | date: appConstants.tableDateTimeFmt }}
						</td>
						<td class="border-start text-nowrap">
							{{ p.last_checked_on | date: appConstants.tableDateTimeFmt }}
						</td>
						<td class="border-start">
							{{ p.test_msg }}
						</td>
						<td class="border-start text-nowrap">
							<button type="button" class="btn btn-outline-primary btn-sm" (click)="runPenTestOnIPs([p.ip], p.username)"
								[disabled]="penTestRunning">
								<fa-icon [icon]="['fas', 'bolt']" size="sm" [spin]="penTestRunning"></fa-icon>
								Re-run Pen Test
							</button>
						</td>
						<td class="border-start text-nowrap">
							<a [href]="'http://' + p.ip + ':4444/'" target="_blank" class="ms-1" ngbTooltip="Login Page">
								<fa-icon [icon]="['fas', 'sign-in-alt']" size="sm"></fa-icon>
							</a>
						</td>
						<td class="border-start text-nowrap">
							<a [href]="'https://ipinfo.io/' + p.ip" target="_blank" class="ms-1" ngbTooltip="IP Info">
								<fa-icon [icon]="['fas', 'external-link-square-alt']" size="sm"></fa-icon>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && (!!organization && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for {{ organization.name }}.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

		<div class="tab-pane" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">
			<div *ngIf="!loading && (!journals || journals.length === 0)" class="cp-light-message">
				No journal entries for this Organization.
			</div>

			<button *ngIf="canAddJournals" type="button" class="btn btn-outline-primary btn-sm mb-2 me-1"
				[routerLink]="['/' + appConstants.urls.adminlogs, 'journal', 'organization', id]">
				<fa-icon [icon]="appConstants.sectionIcons.journals"></fa-icon> Add Journal Entry
			</button>

			<app-journals-table #journalsTable1></app-journals-table>
		</div>

	</div>
</div>

<div class="modal" id="deleteModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Delete this Organization</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeDeleteModalButton">

				</button>
			</div>

			<div class="modal-body">
				<form *ngIf="deleteForm" [formGroup]="deleteForm" autocomplete="off">

					<div class="gj-form-group-other">
						If you delete this Organization, it will be permanently deleted and cannot be recovered.
						<br /><br />

						If you are sure, type <span class="fw-bold">delete</span> in the box below and
						press the button.
					</div>

					<div *ngIf="showDeleteMsg" class="gj-warning-message">
						You must type <span class="fw-bold">delete</span> in the box below.
					</div>

					<div class="form-group">
						<input type="text" id="confirmation" formControlName="confirmation" class="form-control" required />
						<label for="confirmation">Enter 'delete' to confirm</label>
					</div>

					<div class="text-center mt-2">
						<button *ngIf="canDelete" type="button" [disabled]="loading" (click)="delete()" class="btn btn-sm btn-danger">
							<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
							Delete this Organization
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>