import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import { RoleGuard } from 'client/app/guards/role.guard';
import { LicenseKeyListComponent } from './license-key-list/license-key-list.component';
import { LicenseKeyFormComponent } from './license-key-form/license-key-form.component';
import { AddLicenseKeysFormComponent } from './add-license-keys-form/add-license-keys-form.component';
import { ShareLicenseKeysFormComponent } from './share-license-keys-form/share-license-keys-form.component';
import { AddHostidsFormComponent } from './add-hostids-form/add-hostids-form.component';
import { HostidFormComponent } from './hostid-form/hostid-form.component';
import { LicenseMultiComponent } from './license-multi/license-multi.component';
import { SnoozeFormComponent } from './snooze-form/snooze-form.component';

// import { LicenseKeyComponent } from './license-key/license-key.component';
// import { LicenseKeyHostComponent } from './license-key-host/license-key-host.component';

const routes: Routes = [
	{
		path: '',
		component: LicenseKeyListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: 'register/:keys',
		component: AddLicenseKeysFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: 'register',
		component: AddLicenseKeysFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: 'share',
		component: ShareLicenseKeysFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: ':id/share',
		component: ShareLicenseKeysFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: ':id/edit',
		component: LicenseKeyFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: ':id/host/:hostid',
		component: LicenseMultiComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	},
	{
		path: ':id/snooze',
		component: SnoozeFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: ':id/snooze/edit/:snoozeid',
		component: SnoozeFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: ':id/snooze/:type',
		component: SnoozeFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: ':key/register-hostids',
		component: AddHostidsFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	},
	{
		path: ':key/host/:hostid/edit',
		component: HostidFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole },
		resolve: []
	},
	{
		path: ':id',
		component: LicenseMultiComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.basicUserRole }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyKeysRoutingModule { }
