<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="appConstants.sectionIcons.reports" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Reports
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-primary me-2" [routerLink]="['/'+ appConstants.urls.myfilesharing]">
				<fa-icon [icon]="appConstants.sectionIcons.myfilesharing" class="cp-user-nav-item" size="sm"></fa-icon>
				<span class="cp-action-button-text">Go to File Sharing</span>
			</button>
		</div>
	</div>
</div>

<div class="list-wrapper">

	<div class="container">
		<div class="row">
			<div *ngFor="let item of appConstants.reportTypes" class="col-6 mb-2">
				<div class="border p-2" [hidden]="!item.showInList">
					<div class="fw-bold">{{ item.label }}</div>
					<div class="cp-pre-text">{{ item.description }}</div>

					<button *ngIf="!item.modal || item.modal === ''" type="button" class="btn btn-outline-primary btn-sm me-1"
						(click)="runReport(item.className)" ngbTooltip="Click to run report" placement="end">
						<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
						</fa-icon>
						Run Report
					</button>

					<button *ngIf="item.modal && item.modal !== ''" type="button" class="btn btn-outline-primary btn-sm me-1"
						ngbTooltip="Click to specify parameters for report" data-bs-toggle="modal" [attr.data-bs-target]="'#' + item.modal"
						placement="end">
						<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
						</fa-icon>
						Run Report
					</button>
				</div>
			</div>
		</div>
	</div>

	<!-- <div *ngFor="let t of topCache; let i = index">
		{{ i }}
		{{ t['object_type'] }}
		{{ t['object_id'] }}
		{{ t['object_name'] }}
		{{ textTools.formattedMB( t['used']) }}
	</div> -->


</div>

<!-- modals -->
<div>
	<div class="modal" id="CommercialAnalysisReportModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Commercial Analysis on Production Broadcaster Keys</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
						id="closeCommercialAnalysisReportModal">

					</button>
				</div>

				<div class="modal-body">
					<form [formGroup]="commercialAnalysisReportForm" *ngIf="commercialAnalysisReportForm">

						<div class="p-2">
							Start
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="Start (yyyy-mm-dd)" id="startDate" formControlName="startDate"
									firstDayOfWeek="7" ngbDatepicker #d1="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							End
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="End (yyyy-mm-dd)" id="endDate" formControlName="endDate"
									ngbDatepicker firstDayOfWeek="7" #d2="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							Note that the end date is not inclusive. In other words, all data from the start date up to, but
							not including the end date will be used.
						</div>

						<div class="p-2">
							Also note that the today's data is not available.
						</div>

						<div class="p-2  text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1" (click)="runCommercialAnalysisReport()"
								ngbTooltip="Click to run report">
								<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
								</fa-icon>
								Run Report
							</button>
						</div>
					</form>

					<div class="cp-form-padding"></div>

				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="SystemWideUsageReportModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">System Wide Usage Options</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeSystemWideUsageReportModal">
					</button>
				</div>

				<div class="modal-body">
					<form [formGroup]="systemWideUsageReportForm" *ngIf="systemWideUsageReportForm">

						<div class="p-2">
							Start
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="Start (yyyy-mm-dd)" id="startDate" formControlName="startDate"
									firstDayOfWeek="7" ngbDatepicker #d1="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							End
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="End (yyyy-mm-dd)" id="endDate" formControlName="endDate"
									ngbDatepicker firstDayOfWeek="7" #d2="ngbDatepicker">
							</div>
						</div>

						<div>
							<label>
								Key Product
							</label>
						</div>
						<div class="form-check ms-1 mb-1"
							*ngFor="let dsSelection of systemWideUsageReportKeyProductSelections; let i = index">
							<input class="form-check-input" type="radio" formControlName="keyProduct" name="keyProduct"
								[id]="'keyProduct_' + dsSelection.value" [value]="dsSelection.value" />
							<label class="form-check-label" [for]="'keyProduct_' + dsSelection.value">
								{{ dsSelection.label }}
							</label>
						</div>

						<div class="p-2">
							Note that the end date is not inclusive. In other words, all data from the start date up to, but
							not including the end date will be used.
						</div>

						<div class="p-2">
							Also note that the today's data is not available.
						</div>

						<div class="p-2  text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1" (click)="runSystemWideUsageReport()"
								ngbTooltip="Click to run report">
								<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
								</fa-icon>
								Run Report
							</button>
						</div>
					</form>

					<div class="cp-form-padding"></div>

				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="TranscodeReportModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Transcode Report</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeTranscodeReportModal">

					</button>
				</div>

				<div class="modal-body">
					<form [formGroup]="transcodeReportForm" *ngIf="transcodeReportForm">

						<div class="p-2">
							Start
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d3.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="Start (yyyy-mm-dd)" id="startDate" formControlName="startDate"
									firstDayOfWeek="7" ngbDatepicker #d3="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							End
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d4.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="End (yyyy-mm-dd)" id="endDate" formControlName="endDate"
									ngbDatepicker firstDayOfWeek="7" #d4="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							Note that the end date is not inclusive. In other words, all data from the start date up to, but
							not including the end date will be used.
						</div>

						<div class="p-2">
							Also note that the today's data is not available.
						</div>

						<div class="p-2  text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1" (click)="runTranscodeReport()"
								ngbTooltip="Click to run report">
								<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
								</fa-icon>
								Run Report
							</button>
						</div>
					</form>

					<div class="cp-form-padding"></div>

				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="UsagePatternsReportModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Usage Patterns Report</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeUsagePatternsReportModal">
					</button>
				</div>

				<div class="modal-body">
					<form [formGroup]="usagePatternsReportForm" *ngIf="usagePatternsReportForm">
						<div class="form-check ms-1 mb-1">
							<input class="form-check-input" type="radio" formControlName="sliceMode" name="sliceMode" id="sliceMode_block"
								value="block" />
							<label class="form-check-label" for="sliceMode_block">
								By Block of Time
							</label>
						</div>

						<div class="form-group ms-3" [ngClass]="sliceMode.value !== 'block' ? 'fst-italic' : ''">
							<div class="form-check form-check-inline mb-1" *ngFor="let block of usagePatternsReportBlocks; let i = index">
								<input class="form-check-input" type="radio" formControlName="sliceDaysAlt" name="sliceDaysAlt"
									[id]="'sliceDaysAlt_' + (block.value * -1)" [value]="block.value" />
								<label class="form-check-label" [for]="'sliceDaysAlt_' + (block.value * -1)">
									{{ block.label }}
								</label>
							</div>
						</div>

						<div class="form-check ms-1 mb-1">
							<input class="form-check-input" type="radio" formControlName="sliceMode" name="sliceMode" id="sliceMode_days"
								value="days" />
							<label class="form-check-label" for="sliceMode_days">
								By # Days
							</label>
						</div>

						<div class="form-group ms-3" [ngClass]="sliceMode.value !== 'days' ? 'fst-italic' : ''">
							<label for="sliceDays">
								# Days In Each Time Slice
								<!-- <fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Key type(s) to include in the report">
							</fa-icon> -->
							</label>
							<input type="text" id="sliceDays" formControlName="sliceDays" class="form-control" />
						</div>

						<div class="form-group">
							<label for="numSlices">
								# of Time Slices
								<!-- <fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Key type(s) to include in the report">
							</fa-icon> -->
							</label>
							<input type="text" id="numSlices" formControlName="numSlices" class="form-control" />
						</div>

						<div class="form-group">
							<label for="keyTypes">
								Key Type(s)
								<fa-icon [icon]="['fas', 'question-circle']" placement="end"
									ngbTooltip="Key type(s) to include in the report">
								</fa-icon>
							</label>
							<ng-select [items]="appConstants.searchableKeyTypesSelections" bindValue="value" bindLabel="label" id="keyTypes"
								formControlName="keyTypes" [multiple]="true">
							</ng-select>
						</div>

						<div>
							<label>
								Data Source
								<!-- <fa-icon [icon]="['fas', 'question-circle']" placement="end"
								ngbTooltip="Key type(s) to include in the report">
							</fa-icon> -->
							</label>
						</div>
						<div class="form-check ms-1 mb-1"
							*ngFor="let dsSelection of usagePatternsReportDataSourceSelections; let i = index">
							<input class="form-check-input" type="radio" formControlName="dataSource" name="dataSource"
								[id]="'dataSource_' + dsSelection.value" [value]="dsSelection.value" />
							<label class="form-check-label" [for]="'dataSource_' + dsSelection.value">
								{{ dsSelection.label }}
								<fa-icon *ngIf="dsSelection.information !== ''" [icon]="['fas', 'question-circle']"
									[ngbTooltip]="dsSelection.information"></fa-icon>
							</label>
						</div>

						<div class="form-group">
							<label for="orgIds">
								Specific Organization(s) (optional)
							</label>
							<ng-select [items]="orgSelections" bindValue="value" bindLabel="label" id="orgIds" formControlName="orgIds"
								[multiple]="true" placeholder="Organization(s)" placement="bottom"
								ngbTooltip="Filter search results to one or more specific organizations" [closeOnSelect]="false">
							</ng-select>
						</div>

						<div class="p-2 text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1" (click)="runUsagePatternsReport()"
								ngbTooltip="Click to run report">
								<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
								</fa-icon>
								Run Report
							</button>
						</div>
					</form>

					<div class="cp-form-padding"></div>

				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="IncidentsReportModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Incidents Report</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeIncidentsReportModal">

					</button>
				</div>

				<div class="modal-body">
					<form [formGroup]="incidentsReportForm" *ngIf="incidentsReportForm">

						<div class="p-2">
							Start
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d3.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="Start (yyyy-mm-dd)" id="startDate" formControlName="startDate"
									firstDayOfWeek="7" ngbDatepicker #d3="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							End
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d4.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="End (yyyy-mm-dd)" id="endDate" formControlName="endDate"
									ngbDatepicker firstDayOfWeek="7" #d4="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							Note that the end date is not inclusive. In other words, all data from the start date up to, but
							not including the end date will be used.
						</div>

						<div class="p-2  text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1" (click)="runIncidentsReport()"
								ngbTooltip="Click to run report">
								<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
								</fa-icon>
								Run Report
							</button>
						</div>
					</form>

					<div class="cp-form-padding"></div>

				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="BxVersionReportModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Usage Patterns Report</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBxVersionReportModal">

					</button>
				</div>

				<div class="modal-body">
					<form [formGroup]="bxVersionReportForm" *ngIf="bxVersionReportForm">
						<div class="form-group">
							<label for="keyTypes">
								Key Type(s)
								<fa-icon [icon]="['fas', 'question-circle']" placement="end"
									ngbTooltip="Key type(s) to include in the report">
								</fa-icon>
							</label>
							<ng-select [items]="appConstants.searchableKeyTypesSelections" bindValue="value" bindLabel="label" id="keyTypes"
								formControlName="keyTypes" [multiple]="true">
							</ng-select>
						</div>

						<div class="p-2 text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1" (click)="runBxVersionReport()"
								ngbTooltip="Click to run report">
								<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
								</fa-icon>
								Run Report
							</button>
						</div>
					</form>

					<div class="cp-form-padding"></div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal" id="AccountReviewReportModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Account Review Report</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeAccountReviewReportModal">

					</button>
				</div>

				<div class="modal-body">
					<form [formGroup]="accountReviewReportForm" *ngIf="accountReviewReportForm">
						<div *ngIf="topCache && topCache.length > 0" class="form-group text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1 mb-1" (click)="selectTopOrganizations(5)">
								Select Top 5 Organizations
							</button>
							<button type="button" class="btn btn-outline-primary btn-sm me-1 mb-1" (click)="selectTopOrganizations(10)">
								Select Top 10 Organizations
							</button>
							<button type="button" class="btn btn-outline-primary btn-sm me-1 mb-1" (click)="selectTopOrganizations(25)">
								Select Top 25 Organizations
							</button>
						</div>

						<div *ngIf="topCache && topCache.length > 0" class="text-center">
							or
						</div>

						<div class="form-group">
							<label for="orgIds">
								Specific Organization(s)
							</label>
							<ng-select [items]="orgSelections" bindValue="value" bindLabel="label" id="orgIds" formControlName="orgIds"
								[multiple]="true" placeholder="Organization(s)" placement="bottom"
								ngbTooltip="Report on these Organizations" [closeOnSelect]="false">
							</ng-select>
						</div>

						<div class="text-center">
							or
						</div>

						<div class="form-group">
							<label for="orgIds">
								Account Owner
							</label>
							<ng-select [items]="accountOwnerSelections" bindValue="value" bindLabel="label" id="accountOwnerId"
								formControlName="accountOwnerId" [multiple]="false" placeholder="Account Owner" placement="bottom"
								ngbTooltip="Report on all Organizations tied to this Account Owner">
							</ng-select>
						</div>

						<div class="form-group">
							<label for="can_be_changed">Just Show Usage Totals (skip individual protocols)</label>
							<div class="form-check ms-2" *ngFor="let val of yesNo; let i = index">
								<input class="form-check-input" type="radio" formControlName="skipUniqueProtocols"
									name="skipUniqueProtocols" [id]="'skipUniqueProtocols' + i" [value]="val" />
								<label class="form-check-label" [for]="'skipUniqueProtocols' + i">{{ val }}</label>
							</div>
						</div>

						<div class="form-group">
							<label for="can_be_changed">Skip Disabled Organizations</label>
							<div class="form-check ms-2" *ngFor="let val of yesNo; let i = index">
								<input class="form-check-input" type="radio" formControlName="skipDisabledOrgs" name="skipDisabledOrgs"
									[id]="'skipDisabledOrgs' + i" [value]="val" />
								<label class="form-check-label" [for]="'skipDisabledOrgs' + i">{{ val }}</label>
							</div>
						</div>

						<div class="row gx-1 justify-content-center">
							<div *ngFor="let accountReviewTimeFrame of monthTimeFrames"
								(click)="setDateForAccountRevew(accountReviewTimeFrame)" class="col-auto cp-pointer">
								<div class="border p-1 m-1">
									<span class="text-info">
										{{ accountReviewTimeFrame.label }}
									</span>
								</div>
							</div>
						</div>

						<div class="row gx-1 justify-content-center">
							<div *ngFor="let accountReviewTimeFrame of quarterTimeFrames"
								(click)="setDateForAccountRevew(accountReviewTimeFrame)" class="col-auto cp-pointer">
								<div class="border p-1 m-1">
									<span class="text-info">
										{{ accountReviewTimeFrame.label }}
									</span>
								</div>
							</div>
						</div>

						<div class="p-2">
							Start
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d3.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="Start (yyyy-mm-dd)" id="startDate" formControlName="startDate"
									firstDayOfWeek="7" ngbDatepicker #d3="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							End
							<div class="input-group">
								<button class="btn btn-outline-secondary" (click)="d4.toggle()" type="button">
									<fa-icon icon="calendar-alt"></fa-icon>
								</button>
								<input class="form-control" placeholder="End (yyyy-mm-dd)" id="endDate" formControlName="endDate"
									ngbDatepicker firstDayOfWeek="7" #d4="ngbDatepicker">
							</div>
						</div>

						<div class="p-2">
							Note that the end date is not inclusive. In other words, all data from the start date up to, but
							not including the end date will be used.
						</div>

						<div class="p-2  text-center">
							<button type="button" class="btn btn-outline-primary btn-sm me-1" (click)="runAccountReviewReport()"
								ngbTooltip="Click to run report">
								<fa-icon [icon]="appConstants.sectionIcons.reports" class="me-1">
								</fa-icon>
								Run Report
							</button>
						</div>
					</form>

					<!-- <div class="cp-form-padding"></div> -->

				</div>
			</div>
		</div>
	</div>
</div>